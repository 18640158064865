const en = {
  AUDIO_GUIDE: {
    BANNER: 'Free Audio Guide',
    PRODUCT_SUFFIX: 'with Audio Guide',
  },
  AUTHORISED_AND_TRUSTED_PARTNER: 'Authorised & Trusted Partner',
  AUTHORISED_RESELLER: {
    MAIN_TEXT: 'Authorized reseller',
    SUB_TEXT: 'Authentic experiences from trusted and verified partners.',
  },
  REVIEWS: 'reviews',
  AVAILABLE: 'Available',
  BACK: 'Back',
  BANNER_CTA: 'Buy tickets',
  BANNER_FOOTER_SUBTEXT: {
    NON_PARTNERED_NON_SENSITIVE:
      'Headout is a brand trusted by millions of guests worldwide offering curated experiences to enjoy this attraction.',
    NON_PARTNERED_SENSITIVE:
      "Headout is a brand trusted by millions of guests worldwide offering curated experiences to enjoy this attraction. This is not the venue's website.",
    PARTNERED_NON_SENSITIVE:
      'Headout is an authorized and trusted partner of the venue, offering curated experiences to enjoy this attraction.',
    PARTNERED_SENSITIVE:
      "Headout is an authorized and trusted partner of the venue, offering curated experiences to enjoy this attraction. This is not the venue's website.",
  },
  BANNER_SUBTEXT_DEFAULT_DISCLAIMER:
    'Headout is an authorised and trusted partner of the venue offering a curated selection of the best experiences to enjoy this attraction.',
  BOOK_NOW_CTA: 'Book now',
  BUY_TICKETS_CTA: 'Buy tickets',
  CANCELLATION_POLICY: {
    CANCELLABLE:
      'You can cancel these tickets up to {0} hours before the experience begins and get a full refund.',
    CANCELLABLE_ANYTIME:
      'Free cancellation anytime before the start of your experience',
    CANCELLABLE_DAYS:
      'You can cancel these tickets up to {0} days before the experience begins and get a full refund.',
    EXTENDED_BUT_UNKNOWN_VALIDITY:
      'These tickets can’t be cancelled. However, they are valid for an extended period of time.',
    HEADING: 'Amendment Policy',
    NON_CANCELLABLE_BUT_RESCHEDULABLE:
      "These tickets can't be cancelled. However, they can be rescheduled up to {0} hours before the experience begins.",
    NON_CANCELLABLE_NON_RESCHEDULABLE:
      "These tickets can't be cancelled or rescheduled.",
    VALID_UNTIL_DATE:
      "These tickets can't be cancelled. However, you can use them any time until {0}.",
    VALID_WITHIN_NEXT_DAYS:
      'These tickets can’t be cancelled. However, you can use them any time within the next {0} days.',
    VALID_WITHIN_NEXT_MONTHS:
      'These tickets can’t be cancelled. However, you can use them any time within the next {0} months.',
  },
  CANCELLATION_POLICY_HEADING: 'Cancellation policy',
  CASHBACK: '{0}% cashback',
  CASHBACK_INFO:
    'You will receive <strong>{0}%</strong> cashback when you book this experience. Your cashback will automatically be credited to your Headout account and can be used towards any future booking with Headout.',
  CATEGORY_SLIDER_HEADING: 'Customers Who Booked This Show Also Viewed',
  CHECK_AVAIL: 'Check availability',
  CITY_PAGE: {
    BANNER_TITLE: 'Experience the best of',
    TOP_ATTRACTIONS: 'Top attractions',
    GO_BEYOND: 'Go beyond {0}',
    ALL_DAY_TRIPS: 'See all day trips',
    CITIES_NEARBY: 'Nearby cities',
    POPULAR_CATEGORIES: 'Popular categories',
    GO_TO_GUIDE: 'Go-to {0} guide',
    VIEW_TRAVEL_GUIDE: 'View travel guide',
    EXPLORE: 'Explore {0}',
    GUIDE_SUBHEADING:
      'Exploring {0} has never been easier! Our {0} travel guide has all you need to know about the city, from what to eat and what to see to how to get there, where to stay and plenty more!',
    GUIDE_ITEM_HEADINGS: {
      TRAVEL_GUIDE: 'General information',
      WHERE_TO_EAT: 'What to eat',
      WHERE_TO_STAY: 'Where to stay',
      THINGS_TO_DO: 'What to do',
      WEATHER: 'Best time to visit',
      NEIGHBOURHOODS: 'Local community',
      TRANSPORTATION: 'Getting around',
      TRIP_PLANNER: 'Travel plan',
      FAMILY_TRAVEL: 'Traveling with kids',
      TRAVEL_TIPS: 'Travel tips & hacks',
      SHOPPING: 'Shopping',
      ART_AND_CULTURE: 'Arts & culture',
    },
    MAILER: {
      HEADING: '{0} travel inspiration straight to your inbox',
      SUBHEADING:
        'Explore new cities with curated experiences, instant cashback, exclusive deals and essential travel tips!',
    },
  },
  COOKIE_CONSENT: {
    BASIC_DESCRIPTION:
      'We use cookies and similar technologies for many purposes, including to improve your experience on our site and for ads.',
    MANAGE_PREFERENCES: 'Manage',
    PREFERNCES_SAVED: 'Your preferences have been saved',
    COOKIES: 'Cookies:',
    ACCEPT: 'Okay',
    ALLOW_ALL: 'Allow all',
    DENY_ALL: 'Deny all',
    HEADING: 'We value your privacy, always',
    DESCRIPTION: `*   Cookies are small files placed on Your computer, mobile device, or any other device by Website/Platform, containing the details of Your browsing history among its many uses.
*   We use different types of cookies to track Your usage of Our Platform and store certain information that further helps us to provide You with more personalized services. Certain cookies may get placed on Your Device because of Your usage of any services available on Our Platform.
*   Broadly, We use two different sets of technologies under this head:
    *   **Cookies or Browser Cookies.** it is a small file that automatically gets placed on Your Device when You access Our Platform. You can instruct Your browser to refuse all Cookies. In case, if You do not accept Our Cookies, You may not be able to use some parts of Our Platform.
    *   **Web Beacons.** Also referred to as clear gifs, pixel tags, and single-pixel gifs that are attached in various sections of Our Platform and Our emails that permit us to monitor and understand the activity of Users.
*   These Cookies stored on Your Device can be "Persistent" or "Session" Cookies. Persistent Cookies are the ones that remain on Your Device even when You go offline, whereas, Session Cookies are automatically deleted as soon as You close Your web browser.
*   We use both Session and Persistent Cookies for the purposes set out below:
    *   Necessary / Essential Cookies
        *   _Type:_ Session Cookies
        *   _Purpose:_ These Cookies are essential for You to access different sections of the Platform and if these are not allowed You may not be able to access the services available on the Platform. These cookies further help us to authenticate the Users and prevent any sort of fraudulent activity on the Platform.
    *   Cookies Policy / Notice Acceptance Cookies
        *   _Type:_ Persistent Cookies
        *   _Purpose:_ These Cookies identify if Users have accepted the use of Cookies.
    *   Functionality Cookies
        *   _Type:_ Persistent Cookies
        *   _Purpose:_ These Cookies allow us to remember choices You make when You use the Platform, such as remembering Your login details or language preference. The larger purpose of these Cookies is to provide You with a more personalized experience every time You use the Platform.

All capitalized terms used above are defined in the Privacy Policy. For a detailed understanding please refer [Privacy Policy](/privacy-policy/)
`,
  },
  CITY_GUIDE_DESCRIPTIONS: {
    TRAVEL_GUIDE:
      'Learn about visa requirements, currency exchange, time zone differences, and more to ensure a smooth arrival and enjoyable stay.',
    WHERE_TO_EAT:
      'Indulge in the vibrant culinary scene, savoring diverse flavors and enjoying local delicacies in every corner of the {0}.',
    WHERE_TO_STAY:
      'Discover the best accommodations {0}, offering comfort, convenience, and a memorable experience for your stay.',
    THINGS_TO_DO:
      'Experience a plethora of exciting activities and attractions that cater to every interest and ensure an unforgettable time.',
    WEATHER:
      'Plan your trip wisely and make the most of your visit, as {0} offers enchanting experiences throughout the year.',
    NEIGHBOURHOODS:
      'Get to know the diverse local communities and vibrant neighborhoods that give {0} its unique character.',
    TRANSPORTATION:
      'Navigate {0} with ease using its efficient transportation system, providing convenient options for exploring all its attractions.',
    TRIP_PLANNER:
      'Maximize your time in {0} with carefully curated itineraries, ensuring you make the most of every moment.',
    FAMILY_TRAVEL:
      'Embark on a family-friendly adventure with numerous attractions, parks, and entertainment options that cater to kids of all ages.',
    TRAVEL_TIPS:
      'Discover insider tips and travel hacks to enhance your journey, making your visit to {0} truly exceptional.',
    SHOPPING:
      "Explore a shopper's paradise with a wide range of boutiques, markets, and malls, offering everything from local crafts to high-end fashion.",
    ART_AND_CULTURE:
      'Immerse yourself in the rich art and cultural scene, from captivating museums to vibrant festivals celebrating {0} heritage.',
  },
  CLOSE: 'Close',
  COLLECTION_SLICE_HEADING: 'Top things to do in {0}',
  COMBO_VARIANT: {
    SELECT_CTA: 'Select',
    SELECT_PREFERENCE: 'Select your preference',
    SELECT_OPTION: 'Select your option',
    SELECT_TICKET: 'Select your ticket',
  },
  COMPARE_ALL_DETAILS: 'Compare all details',
  COVID19_ALERT: {
    KEY_TEXT: 'Update on COVID-19',
    LINK: 'https://medium.com/headout/coronavirus-outbreak-cancellation-policy-8fe8e1104b83',
    TEXT: 'You are our priority. Please check our help page to find the latest updates on our flexible cancellation policies',
  },
  CURRENCY: 'Currency',
  CUSTOMER_REVIEW_HEADING: 'Customer reviews',
  DESCRIPTORS: {
    AUDIO_GUIDE: 'Audio guide',
    DURATION: 'Duration: {0}',
    EXTENDED_VALIDITY: 'Extended validity',
    FLEXIBLE_DURATION: 'Flexible duration',
    FREE_CANCELLATION: 'Free cancellation',
    GUIDED_TOUR: 'Guided tour',
    HOTEL_PICKUP: 'Hotel pickup',
    INSTANT_CONFIRMATION: 'Instant confirmation',
    MEALS_INCLUDED: 'Meals included',
    MOBILE_TICKET: 'Mobile ticket',
    SKIP_THE_LINE: 'Skip the line',
    TRANSFERS: 'Transfers included',
    MULTILINGUAL_AUDIO_GUIDE: 'Multilingual audioguide',
    LIVE_GUIDE: 'Live guide',
    FLEXIBLE_CANCELLATION: 'Flexible cancellation',
  },
  DURATION: 'Duration',
  EARLIEST_AVAILABILITY: 'Earliest availability',
  EMAIL_SUBSCRIPTION: {
    EMAIL_FIELD_PLACEHOLDER: 'Enter your email here',
    SIGN_UP: 'Sign up',
    THANK_YOU: 'Thank you!',
    SUBSCRIBED_MSG: 'You are subscribed now',
    ERROR_MSG: 'Please enter a valid email',
  },
  ENTERTAINMENT_MB: {
    BROADWAY: {
      MB_NAME: 'Broadway Theater Tickets',
    },
    LTT: {
      MB_NAME: 'London Theater Tickets',
    },
  },
  EXCLUSIONS: 'Exclusions',
  FEATURE_CARD: {
    HEADING_CUSTOMER: '{0} million +',
    HEADING_RATING: '4.4/5',
    HEADING_SAFE: '100% Safe',
    HEADING_SUPPORT: '24x7 Help center',
    SUB_HEADING_CUSTOMER: 'Happy customers across 10,000+ experiences',
    SUB_HEADING_RATING: '5,000+ verified reviews with 90% excellent score',
    SUB_HEADING_SAFE: 'All the outdoor activities are 100% safe and secure',
    SUB_HEADING_SUPPORT:
      'Have a question? Live chat with local experts anywhere, anytime',
  },
  FLEXIBLE: 'Flexible',
  FOOTER: {
    CALL_US: 'Call us',
    CHAT_WITH_US: 'Chat with us',
    COMPANY_DETAILS: 'Company details',
    ABOUT_US: 'About us',
    INFORMATION: 'Information',
    GMAPS_DISCLAIMER:
      'All screenshots of maps found on this page are sourced from Google in accordance with fair use. All rights to such screenshots lie with the specific platform, and their use is solely for informational or guidance purposes.',
    DISCLAIMER:
      'This website is not the official website of the <attraction>. It is run by Headout and works with attractions and tour operators to provide incredible experiences at the tap of a button.',
    EMAIL_US: 'Email us',
    GET_HELP: 'Get help',
    LEGAL: 'Legal',
    PRIVACY_POLICY: 'Privacy policy',
    QUICK_LINKS: 'Quick Links',
    TERMS_AND_CONDITIONS: 'Terms & conditions',
    COPYRIGHT: '© Headout Inc.',
    MADE_WITH: 'Made with ',
    ALL_OVER_THE: ' all over the ',
    TERMS_OF_USAGE: 'Terms of usage',
    GET_HELP_24_7: 'Get help 24/7',
    HELP_CENTER: 'Help center',
    COMPANY: 'Company',
    WE_ACCEPT: 'We accept',
    DOWNLOAD_HEADOUT: 'Download the Headout app',
    SCAN_CODES: 'Scan code',
    STAR_VERIFIED: 'click to verify S.T.A.R membership',
    OFFICIAL_TICKET_RETAILER: 'Official ticket retailer',
  },
  FREE_CANCELLATION: 'Free cancellation',
  FREE_CANCELLATION_SUBTEXT:
    'We understand plans change, cancel for free up to 72 hours before the event.',
  FROM: 'From',
  GET_CASHBACK: 'Get {0}% cashback',
  GROUP_TICKETS: 'Group tickets',
  HAPPY_CUSTOMER: {
    MAIN_TEXT: '{0}+ Million happy customers',
    SUB_TEXT:
      'Customer satisfaction is our top priority. Join our happy customer base.',
  },
  HEADOUT: 'Headout',
  HELP_CENTER: {
    MAIN_TEXT: '24x7 help center',
    SUB_TEXT:
      'Questions or concerns? We’re here to help 24/7 — on phone, chat, and email.',
  },
  INCLUSIONS: 'Inclusions',
  LANGUAGE: 'Language',
  LANGUAGES: {
    ENGLISH: 'English',
    SPANISH: 'Spanish',
    FRENCH: 'French',
    ITALIAN: 'Italian',
    GERMAN: 'German',
    PORTUGUESE: 'Portuguese',
    NEDERLANDS: 'Dutch',
    POLISH: 'Polish',
    RUSSIAN: 'Russian',
    DANISH: 'Danish',
    NORWEGIAN: 'Norwegian',
    ROMANIAN: 'Romanian',
    SWEDISH: 'Swedish',
    TURKISH: 'Turkish',
  },
  TOUR_AVAILABLE_LANGUAGES: 'Tour available in {0}',
  LISTICLES: {
    KNOW_MORE: 'Know more',
    SEATING_CHART: 'Seating chart',
    WHY_BOOK_FROM_US: 'Why book from us?',
  },
  MORE_DETAILS: 'More details',
  MORE_CURRENCIES: 'More currencies',
  NEW: 'NEW',
  NEW_ARRIVAL: 'New arrival',
  NEXT_AVAILABLE: 'Next available: ',
  NON_PARTNERED_BANNER_SUBTEXT_DISCLAIMER:
    "Headout is a brand trusted by over {0} million guests that offers a curated selection of the best experiences. This is not the venue's website.",
  NO_RESULT_FOUND: 'Hmm, no results found.',
  OFF: 'Off',
  OPEN_DATED_DESCRIPTOR: 'Book with flexibility - no date selection needed',
  OPENING_DATE: 'Opening date',
  OPENING_ON: 'Opening on',
  OPENS: 'Opens',
  PARTNERED_BANNER_SUBTEXT_DISCLAIMER:
    "Headout is an authorised and trusted partner of the venue offering a curated selection of the best experiences to enjoy this attraction. This is not the venue's website.",
  POPULAR_CURRENCIES: 'Popular currencies',
  PRICES_STARTING: 'Prices starting',
  PROMO_CODES: {
    APPLY_CODE: 'APPLY CODE',
    APPLIED: 'APPLIED',
    TOOL_TIP_INFO: 'This discount is applied at checkout',
    DESCRIPTION: {
      ABSOLUTE: 'Get {0} off',
      CAPPED: 'Get {0}% off, upto {1}',
      PERCENTAGE: 'Get {0}% off',
    },
    REMOVE: 'REMOVE',
  },
  RATINGS: '{0} ratings',
  READ_MORE: 'Read more',
  READ_MORE_TEXT: 'Read more',
  READ_LESS: 'Read less',
  VIEW_DETAILS: 'View details',
  REOPENING: 'Reopening',
  REOPENING_DATE: 'Reopening date',
  REOPENING_ON: 'Reopening on',
  REOPENS: 'Reopens',
  SAFE_EXPERIENCE: {
    AT_DESCRIPTION: {
      CTA_URL:
        'https://www.austria.info/en/service-and-facts/coronavirus-information/entry-regulations',
      TEXT: '2G regulations apply: access to accommodation, cultural institutions, and restaurants/bars, among others is only possible with proof of full vaccination or past infection. A negative Covid-19 test is no longer sufficient.',
    },
    AU_DESCRIPTION: {
      CTA_URL:
        'https://www.smartraveller.gov.au/COVID-19/COVID-19-vaccinations',
      TEXT: 'Visitors aged 16 and above must show proof of COVID-19 vaccination or medical exemption. ',
    },
    BANNER_CTA: 'Know more',
    BERLIN_DESCRIPTION: {
      CTA_URL:
        'https://www.berlin.de/en/news/coronavirus/7081102-6098215-berlin-introduces-extensive-2G-rule.en.html',
      TEXT: '2G regulations apply: only fully-vaccinated and recovered guests with proof of the same will be granted access. Guests under 18 or those who cannot be vaccinated for medical reasons must present a negative COVID test result at the entrance. Those who cannot be vaccinated for medical reasons should also prove their inability to be vaccinated by means of a medical certificate at the entrance.',
    },
    CTA_TEXT: 'Safety details',
    DESCRIPTION_CTA: 'Know more.',
    EU_DESCRIPTION: {
      CTA_URL:
        'https://www.diplomatie.gouv.fr/en/coming-to-france/coronavirus-advice-for-foreign-nationals-in-france/coronavirus-statements/article/applying-for-a-covid-certificate-if-you-were-vaccinated-abroad-procedure-for',
      TEXT: 'From 21 July 2021, all visitors aged 12 and above must present an EU COVID Certificate. ',
    },
    EU_HEADING: 'EU Covid certificate required',
    EU_PREFIX: 'Note: ',
    EXPERIENCE_DESCRIPTION:
      'This experience has been verified to follow best safety standards in compliance with local regulations and WHO',
    FLAG_TEXT: 'Best safety',
    GENERAL_DESCRIPTION:
      "Any experience with this badge has been verified to follow the best safety standards as per the local government's health guidelines.",
    HEADING: 'Best safety standards',
    IT_DESCRIPTION: {
      CTA_URL: 'https://www.dgc.gov.it/web/per-cosa-serve.html#viaggi',
      TEXT: 'From 6 August 2021, all visitors aged 11 and above must present a Green Pass or EU COVID Certificate. ',
    },
    IT_PREFIX: 'Note: ',
    MOBILE_HEADING: 'Safe experiences',
    MODAL: {
      BADGE_DESCRIPTION:
        'This badge on an experience confirms that safety standards have been verified by Us',
      DETAILS: {
        SAFETY_CLEANED_DEFAULT: {
          DESCRIPTION:
            'All equipments, vehicles and high-touch areas are sanitized after every single use. Just sit back and enjoy.',
          HEADING: 'All equipments and surfaces are sanitized',
        },
        SAFETY_CLEANED_EQUIPMENTS: {
          DESCRIPTION:
            'All the equipments available during your experience are thoroughly sanitized after every single use. Always.',
          HEADING: 'All equipments and surfaces are sanitized',
        },
        SAFETY_CLEANED_VEHICLES: {
          DESCRIPTION:
            'All the vehicles available during your experience are thoroughly sanitized after every single use. Always',
          HEADING: 'All vehicles are sanitized',
        },
        SAFETY_HANDWASH: {
          DESCRIPTION:
            "As a safe experience, you will have access to handwash and sanitation stations throughout your time here. We've got you covered. ",
          HEADING: 'Handwash and sanitation facilities available throughout',
        },
        SAFETY_MASK_BOTH: {
          DESCRIPTION:
            'Masks are mandatory for all guests and staff members in this experience. This helps everyone have a great time with utmost safety.',
          HEADING: 'Everyone wears masks for your safety',
        },
        SAFETY_MASK_DEFAULT: {
          DESCRIPTION:
            'Our partners who have been verified as a Safe Experience follow local regulations in place around face masks. If mandatory, all guests and staff members will wear masks. They take every step possible to ensure your comfort and safety.',
          HEADING: 'Everyone wears masks for your safety',
        },
        SAFETY_MASK_GUEST: {
          DESCRIPTION:
            'Masks are mandatory for all guests in this experience. This helps everyone have a great time with utmost safety.',
          HEADING: 'Everyone wears masks for your safety',
        },
        SAFETY_MASK_STAFF: {
          DESCRIPTION:
            'All staff members at this experience wear face masks to ensure you can have all the fun minus all the worry.',
          HEADING: 'Everyone wears masks for your safety',
        },
        SAFETY_RESTRICTED_CAPACITY: {
          DESCRIPTION:
            'Social distancing guidelines are applicable throughout this experience. Capacity restrictions are in place to ensure social distancing.',
          HEADING: 'Social distancing guidelines are being followed',
        },
        SAFETY_RESTRICTED_CAPACITY_NO_GROUPS: {
          DESCRIPTION:
            "Social distancing guidelines are applicable throughout this experience. Capacity restrictions are in place to ensure social distancing. In the same light, large groups aren't allowed here.",
          HEADING: 'Social distancing guidelines are being followed',
        },
        SAFETY_SOCIAL_DISTANCING: {
          DESCRIPTION:
            'Social distancing guidelines are being adhered to at all times through this experience.',
          HEADING: 'Social distancing guidelines are being followed',
        },
        SAFETY_SOCIAL_DISTANCING_DEFAULT: {
          DESCRIPTION:
            'Social distancing guidelines are being adhered to at all times through this experience. To enforce this, there might be capacity restrictions and group limits in place. Breathe easy.',
          HEADING: 'Social distancing guidelines are being followed',
        },
        SAFETY_SOCIAL_DISTANCING_NO_GROUPS: {
          DESCRIPTION:
            "Social distancing guidelines are being adhered to at all times through this experience. In the same light, large groups aren't allowed here.",
          HEADING: 'Social distancing guidelines are being followed',
        },
        SAFETY_TEMPERATURE_DEFAULT: {
          DESCRIPTION:
            'All guests will have their temperature checked at the start of the experience to ensure safety for all. All staff members are checked for high temperature multiple times a day.',
          HEADING: 'Temperature checks in place',
        },
        SAFETY_TEMPERATURE_GUEST: {
          DESCRIPTION:
            'All guests will have their temperature checked at the start of the experience to ensure safety for all. ',
          HEADING: 'Temperature checks in place',
        },
        SAFETY_TEMPERATURE_STAFF: {
          DESCRIPTION:
            'All staff members are checked for high temperature multiple times. Entry for any member above the prescribed limit is restricted and they are asked to stay at home to recover.',
          HEADING: 'Temperature checks in place',
        },
        SAFETY_TRAINED_STAFF: {
          DESCRIPTION:
            'The staff available throughout the experience are trained as per WHO guidelines and equipped to handle any emergency that comes their way',
          HEADING: 'Staff is trained on WHO guidelines',
        },
      },
      HEADING: 'Safe experiences',
      SUB_HEADING:
        "We are working with our partners all over the globe to implement the best safety standards as per WHO guidelines. All the measures taken on ground have been verified and displayed here. It's time to enjoy the world around us again.",
    },
    NL_DESCRIPTION: {
      CTA_URL:
        'https://www.government.nl/topics/coronavirus-covid-19/covid-certificate',
      TEXT: 'Visitors aged 13 and above must have (digital or printed copy of) a COVID Certificate that shows that you have been vaccinated, received a negative test result, or have recently recovered from COVID-19. Wearing a face mask is mandatory. ',
    },
    SG_DESCRIPTION: {
      CTA_URL: 'https://safetravel.ica.gov.sg/health/vtsg',
      TEXT: 'From 13 October 2021, only fully vaccinated visitors can enter in groups of 2. You will have to show proof of vaccination status, a recent negative COVID-19 test result, and/or any recovery documentation before entry. ',
    },
    SHORT_HEADING: 'Best safety standards in place',
  },
  SAFE_EXPERIENCE_NEW: {
    CTA_TEXT: 'Safety details',
    EXPERIENCE_DESCRIPTION:
      'This experience has been verified to follow best safety standards in compliance with local regulations and WHO',
    FLAG_TEXT: 'Best safety',
    GENERAL_DESCRIPTION:
      'This venue is COVID SECURE and follows all safety measures recommended by the government to ensure that you enjoy a safe and socially-distanced theatre-going experience.',
    GENERAL_DESCRIPTION_V2:
      'We’ve developed the industry-wide See it Safely mark, so that when theatres reopen, you can feel confident and safe knowing that the venue is COVID-secure and will be following the latest government and performing arts guidance.',
    HEADING: 'See it safely',
    MOBILE_HEADING: 'Safe experiences',
    MORE_CURRENCIES: 'More currencies',
    MODAL: {
      DETAILS: {
        CONTACTLESS_MEASURES_DEFAULT: {
          DESCRIPTION: `<li>Only print-at-home tickets and e-tickets will be accepted</li>`,
          HEADING: 'Contactless E-tickets',
        },
        PREVENTIVE_SAFETY_MEASURES_DEFAULT: {
          DESCRIPTION: `<li>Protective gear such as masks are mandatory to enter the venue</li>
            <li>All viewers will be subjected to temperature checks before entering the venue</li>`,
          HEADING: 'Preventive safety measures',
        },
        SANITIZATION_MEASURES_DEFAULT: {
          DESCRIPTION: `<li>Protective gear such as masks are mandatory to enter the venue</li>
            <li>All viewers will be subjected to temperature checks before entering the venue</li>`,
          HEADING: 'Sanitization measures',
        },
        SOCIAL_DISTANCING_MEASURES_DEFAULT: {
          DESCRIPTION: `<li>The venue has been redesigned to minimize contact among all viewers</li>
            <li>Different groups will be seated separately</li>
            <li>Pre-ordering of interval snacks will be facilitated wherever possible</li>
            <li>Cashless and paperless transactions are recommended.</li>
            <li>All viewers are requested to keep their belongings to a minimum since cloakroom facilities may be closed</li>
            <li>Activities such as taking photographs with the artists or autograph signing may be restricted</li>
            `,
          HEADING: 'Social distancing measures',
        },
      },
      HEADING: 'See it safely',
      SUB_HEADING:
        'Wondering about all that’s being done to keep you safe? Find out more about the safety measures and COVID-safe protocols theatres have implemented to ensure your safety.',
    },
    SHORT_HEADING: 'Best safety standards in place',
  },
  SANITARY_ALERT: {
    KEY_TEXT: 'Your safety is our priority',
    TEXT: "We've taken significant steps to improve cleanliness using disinfectants with comprehensive and frequent surface cleaning. Crowds are also being controlled to prevent congestion.",
  },
  SAVE: 'Save {0}%',
  SAVE_PERCENT: 'Save {0}%',
  SAVE_UPTO_PERCENT: 'Save upto {0}%',
  SEE_ALL: 'See all',
  SEARCH: 'Search',
  POPULARITY: 'Popularity',
  PRICE: 'Price',
  SEE_MORE_SHOWS: 'See more shows',
  SELECT_CITY: 'Select city',
  SELECT_SECTION: 'Select section',
  SEE_ALL_PHOTOS: 'See all photos',
  SHOW_CLOSED: 'Show closed',
  SHOW_LESS: 'Show less',
  SHOW_LESS_TEXT: 'Show less',
  SHOW_MORE: 'Show more',
  SHOW_PAGE: {
    ABOUT_SHOW: 'About Show',
    ABOUT_THEATRE: 'About Theatre',
    ADDITIONAL_INFORMATION: 'Additional Information',
    AGE_LIMIT: 'Age Limit',
    AGE_SUITABILITY: 'Age Suitability',
    CRITIC_REVIEW: 'Critic Review',
    ANSWER: ['A-'],
    BLOG_SHOW_PAGE: 'Blog Show Page',
    BLOG_SUMMARY: 'Blog Summary',
    BLOG_THEATRE_PAGE: 'Blog Theatre Page',
    BLOG_THEATRE_SEATING_PLAN: 'Blog Theatre Seating Plan',
    CANCELLATION_AND_REFUNDS: 'Cancellation & Refunds',
    CANCELLATION_POLICY: 'Cancellation Policy',
    CAST_AND_CREW: 'Cast & Crew',
    CLOSING_DATE: 'Closing Date',
    CLOSING_DATE_SPECIAL_OFFER: 'Closing Date Special Offer',
    DURATION: 'Duration',
    FACILITIES_AND_ACCESSIBILITY: 'Facilities & Accessibility',
    FREQUENTLY_ASKED_QUESTIONS: 'Frequently Asked Questions',
    GETTING_THERE: 'Getting There',
    GETTING_THERE_MAP_LINK: 'Getting There Map Link',
    GOOGLE_MAP: 'Google Map',
    HIGHLIGHTS: 'Highlights',
    LISTICLE_SHOW_SUMMARY: 'Listicle Show Summary',
    LISTICLE_SHOW_WHY_WATCH: 'Listicle Show Why Watch',
    OPENING_DATE: 'Opening Date',
    QUESTION: ['Q-'],
    SAFETY_BANNER: 'Safety Banner',
    SHOW_DETAILS: 'Show Details',
    SHOW_TIMINGS: 'Show Timings',
    SHOW_TYPE: 'Show Type',
    SPECIAL_OFFER: 'Special Offer',
    THE_STORY: 'The Story',
    THEATRE_ADDRESS: 'Theatre Address',
    THEATRE_CAPACITY: 'Theatre Capacity',
    THEATRE_NAME: 'Theatre Name',
    TICKETS: 'Tickets',
    TICKETS_REDEMPTION: 'Tickets Redemption',
    TOP_SONGS: 'Top Songs',
    YOUR_TICKETS: 'Your Tickets',
    ABOUT_CONCERT: 'About Concert',
    PROGRAM: 'Program',
  },
  SNEAK_PEEK: 'Sneak Peek',
  SORT_BY: 'Sort by',
  SPECIAL_OFFER: 'Special Offer',
  THEATRE: 'Theatre',
  THEATRE_PAGE: {
    NEARBY_THEATRES: 'Nearby theatres',
    NOW_PLAYING: 'Now Playing',
    UPCOMING_SHOWS: 'Upcoming Shows',
    PAST_SHOWS: 'Past shows',
    FIND_BEST_SEATS: 'Find best seats',
  },
  TICKET_CAN_BE_CANCELED:
    'You may cancel up to {hours} hours in advance for a full refund.',
  TICKET_CANNOT_BE_CANCELED:
    'This ticket cannot be canceled but can be rescheduled upon request.',
  TICKETS: 'Tickets',
  TIMINGS: 'Timings',
  SPECIAL_PRODUCT_HEADING: 'Most loved',
  SPECIAL_PRODUCT_DESCRIPTORS: {
    LOCAL_GUIDES: 'Expert local guides',
    ENGAGING_STORIES: 'Engaging stories',
    TOP_RATED: 'Top-rated',
  },
  GUIDED_TOUR_REVIEWS: [
    'Our guide Angela was great and full of knowledge of the venue',
    'The guide was very happy to answer questions about the tour',
    'Our guide was great & she even gave us a great lunch recommendation!',
    'The organization and our guide made the experience very pleasant',
    'A lot of educational content, I will surely do it again with my kids',
    'The tour was well organised and just the right time length as well',
    'Our guide really knew his stuff and his passion for history was clear',
    'Good guide and organization! Will recommend!!!',
    "Our guide's little fun facts kept us all engaged and interested",
    "The tour went at a good pace and didn't feel rushed",
    'Our guide Maria was able to get spots for our best viewing point',
    "Our guide enriched our visit beyond what we would've achieved on our own",
    'Detailed and deeply moving tour led by passionate guide',
    'Jeff showed us aspects of the venue that we would have missed otherwise',
  ],
  GUIDES_BANNER: 'Explore like a local with our guest-loved super guides.',
  SPECIAL_GUIDED_TOUR_PRODUCT_SUMMARY: [
    'Taking a guided tour is the best way to get the most from this destination. Our expert guides serve as your local buddies, sharing stories, insider gems, and travel recommendations.',
    "Don't just visit - experience!",
  ],
  HEAR_FROM_OUR_GUESTS: 'Hear from our guests',
  GUIDES: 'Guides',
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  TOUR_LIST_HEADING: 'Select your experience',
  TOUR_LIST_SUB_HEADING:
    'Choose from multiple tickets, tours and combo options by verified operators',
  UGC: {
    FOLLOW: 'Follow',
    FOLLOW_IG: 'Follow on Instagram',
    HEADING: 'POV: Your next adventure looks like this',
    SUB_HEADING: 'Curated with 💜 by Headout',
  },
  UNAVAILABLE: 'Unavailable',
  VALIDITY: {
    EXTENDED_BUT_UNKNOWN_VALIDITY:
      'These tickets are valid for an extended duration. The exact details will be present on the ticket.',
    UNTIL_DATE: 'These tickets are valid until {0}.',
    UNTIL_DAYS_FROM_PURCHASE:
      'These tickets are valid for {0} days from the date of purchase.',
    UNTIL_MONTHS_FROM_PURCHASE:
      'These tickets are valid for {0} months from the date of purchase.',
  },
  TABLE_OF_CONTENTS: 'Contents',
  VIEW_LESS: 'View less',
  VIEW_MORE: 'View more',
  WHY_TAKE_THIS_DAY_TRIP: 'Why take this Day Trip?',
  WHY_WATCH: 'Why watch {0}?',
  FIND_ON_MAP: 'Find on map',
  ENTERTAINMENT_MB_LANDING_PAGE: {
    TRUST_BOOSTERS: {
      BOX_OFFICE_TICKETS: {
        NAME: 'Box office tickets',
        DESCRIPTION: 'Instantly confirmed to your phone',
      },
      CHOOSE_YOUR_SEATS: {
        NAME: `Choose your seats`,
        DESCRIPTION: 'The view you want, every time',
      },
      EXCLUSIVE_DEALS: {
        NAME: 'Exclusive deals & discounts',
        DESCRIPTION: `Save big on {0}'s best shows`,
      },
      BOOK_AND_RELAX: {
        NAME: 'Book and relax',
        DESCRIPTION: 'Trusted by {0} million guests and counting',
      },
    },
    YOUR_PICK: 'Your pick',
    BROWSE_BY_CATEGORIES: 'Browse by categories',
    TOP_WEST_END_SHOWS: 'Top West End shows',
    TOP_THEATRE_SHOWS: 'Top {0} shows',
    TOP_SHOWS: 'Top shows',
    SEE_MORE_SHOWS: 'See {0} more shows',
    LAST_MINUTE_TICKETS: 'Last-minute tickets',
    LOVED_BY_MILLIONS: 'Loved by millions',
    TODAY: 'Today',
    TOMORROW: 'Tomorrow',
    SEE_ALL: 'See all',
    GRAB_YOUR_TICKETS: 'Grab your tickets now',
  },
  TAG_NAME: {
    BALLET: 'Ballet',
    CABARET: 'Cabaret',
    CHRISTMAS: 'Christmas',
    CIRCUS: 'Circus',
    CLASSIC: 'Classic',
    COMEDY: 'Comedy',
    COMING_SOON: 'Coming soon',
    CRITICS_CHOICE: "Critics' choice",
    DANCE: 'Dance',
    DISNEY: 'Disney',
    DRAMA: 'Drama',
    ENGLISH_NATIONAL_BALLET: 'English National Ballet',
    ENGLISH_NATIONAL_OPERA: 'English National Opera',
    FANTASY: 'Fantasy',
    HALLOWEEN: 'Halloween',
    IMMERSIVE_THEATRE: 'Immersive Theatre',
    JUKEBOX: 'Jukebox',
    KIDS: 'Kids',
    MAGIC: 'Magic',
    MUSICALS: 'Musicals',
    NEW_ARRIVAL: 'New Arrival',
    OFF_WEST_END: 'Off West End',
    OLIVIER_WINNER: 'Olivier Winner',
    OPERA: 'Opera',
    PANTOMIMES: 'Pantomimes',
    PLAYS: 'Plays',
    ROMANCE: 'Romance',
    SHAKESPEARE: 'Shakespeare',
    STARS_ON_STAGE: 'Stars on Stage',
    THEATRICAL_CONCERT: 'Theatrical Concert',
    TRUE_STORY: 'True Story',
    TONY_WINNER: 'Tony Winner',
    OFF_BROADWAY: 'Off Broadway',
  },
  INVENTORY_UNAVAILABLE: {
    HEADING: 'Uh oh, we’ve a problem',
    MESSAGE: `Sorry! All experiences for this date are either sold out or
    unavailable. But we’ve loads of them available on other dates. All
    you gotta do is be flexible.`,
    CTA: 'View all dates',
  },
  ALL_DATES: 'All dates',
  CATEGORY_HEADER: {
    MAIN_MENU: 'Main menu',
    EXPLORE: 'Explore',
    VISIT: 'Visit',
    THINGS_TO_DO: 'Things to do',
    CITY_ATTRACTIONS: '{0} attractions',
    TOP_THINGS_TO_DO: 'Top things to do',
    CITY_TOURS: '{0} tours',
    CITY_GUIDE: 'City guide',
    ATTRACTIONS: 'Attractions',
    CRUISES: 'Cruises',
    THEMES: 'Themes',
    ABOUT: 'About',
    TOURS: 'Tours',
    SKIP_THE_LINE: 'Skip the Line Tours',
    GUIDED_TOURS: 'Guided Tours',
    NIGHT_TOURS: 'Night Tours',
    FOOD_TOURS: 'Food Tours',
    HISTORY: 'History',
    ARCHITECTURE: 'Architecture',
    DESIGN: 'Design',
    INSIDE: 'Inside',
    COLLECTIONS: 'Collections',
    PAINTINGS: 'Paintings',
    ANIMALS_EXHIBITS: 'Animals and Exhibits',
    HABITATS: 'Habitats',
    CONSERVATION: 'Conservation',
    PROGRAMMES: 'Programmes',
    A2_SIGHTSEEING_CRUISES: 'Sightseeing Cruises',
    A2_DINNER_CRUISES: 'Dinner Cruises',
    A2_EVENING_CRUISES: 'Evening Cruises',
    A2_LUNCH_CRUISES: 'Lunch Cruises',
    A2_YACHT_TOURS: 'Yacht Tours',
    PLAN_YOUR_VISIT: 'Plan Your Visit',
    TIMINGS: 'Timings',
    DIRECTIONS: 'Directions',
    PARKING: 'Parking',
    ROUTES: 'Routes',
    REQUIREMENTS: 'Requirements',
    LOCATION: 'Location',
    RULES: 'Rules',
    FAQs: 'FAQs',
    ENTRANCES: 'Entrances',
    RESTAURANTS: 'Restaurants',
    FACTS: 'Facts',
    TIPS: 'Tips',
    MAP: 'Map',
    RIDES: 'Rides',
    SHOWS: 'Shows',
    DINING: 'Dining',
    SHOPPING: 'Shopping',
    EVENTS: 'Events',
    HALLOWEEN: 'Halloween',
    CHRISTMAS: 'Christmas',
    NEW_YEARS_EVE: "New Year's eve",
    TRAVEL_GUIDE: 'Travel guide',
    TRIP_PLANNER: 'Trip planner',
    ITINERARY: 'Itinerary',
    TRAVEL_TIPS: 'Travel tips',
    WEATHER: 'Weather',
    BEST_TIME_TO_VISIT: 'Best time to visit',
    JANUARY: 'January',
    FEBRUARY: 'February',
    MARCH: 'March',
    APRIL: 'April',
    MAY: 'May',
    JUNE: 'June',
    JULY: 'July',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'October',
    NOVEMBER: 'November',
    DECEMBER: 'December',
    WHERE_TO_EAT: 'Where to eat',
    WHERE_TO_STAY: 'Where to stay',
    NEIGHBOURHOODS: 'Neighbourhoods',
    FAMILY_TRAVEL: 'Family travel',
    TRANSPORTATION: 'Transportation',
    FESTIVALS_EVENTS: 'Festivals and events',
  },
  BREADCRUMBS: {
    HOME: 'Home',
    TRAVEL_GUIDE: 'Travel Guide',
    THINGS_TO_DO: 'Things to do in {0}',
    SHOW_NAME_TICKETS: '{0} - Tickets',
    LTT_HOME: 'London Theatre Tickets',
    LTT_VENUE_PAGE_HOME: 'Theatres in London',
    BROADWAY_HOME: 'Broadway Show Tickets',
    BROADWAY_VENUE_PAGE_HOME: 'Broadway Theatres',
    VIENNA_CONCERT_HOME: 'Vienna Concert Tickets',
    VIENNA_CONCERT_VENUE_PAGE_HOME: 'Concert Halls in Vienna',
    ATTRACTIONS: 'Attractions',
    A2_SIGHTSEEING_CRUISES: 'Sightseeing Cruises',
    A2_DINNER_CRUISES: 'Dinner Cruises',
    A2_EVENING_CRUISES: 'Evening Cruises',
    A2_LUNCH_CRUISES: 'Lunch Cruises',
    A2_YACHT_TOURS: 'Yacht Tours',
    LONDON_THEATRE_NEWS: 'London Theatre News',
    BROADWAY_SHOW_NEWS: 'Broadway Show News',
    NEWS_PAGE: 'News Page',
    REVIEWS_PAGE_BANNER_HEADING: '{0} reviews',
  },
  AMENITIES: {
    Restaurant: 'Restaurant',
    Cafe: 'Cafe',
    'Dedicated Parking': 'Dedicated Parking',
    Elevators: 'Elevators',
    Lift: 'Lift',
    'Wheelchair Accessible': 'Wheelchair Accessible',
    Cloakroom: 'Cloakroom',
    'Air Conditioning': 'Air Conditioning',
    WiFi: 'WiFi',
    Bar: 'Bar',
    'Water Fountain': 'Water Fountain',
    Washrooms: 'Washrooms',
    Toilets: 'Toilets',
    Binoculars: 'Binoculars',
    'Adapted Toilets': 'Adapted Toilets',
    'Accessible Toilets': 'Accessible Toilets',
    'Assistive Listening System': 'Assistive Listening System',
    'Hearing Assistance': 'Hearing Assistance',
    'Booster Cushions': 'Booster Cushions',
    Shop: 'Shop',
    'Souvenir Shop': 'Souvenir Shop',
    'Guide Dogs': 'Guide Dogs',
    'Baby Changing Facilities': 'Baby Changing Facilities',
    'Security Check': 'Security Check',
    Ramp: 'Ramp',
    'Level Access': 'Level Access',
  },
  CALENDAR: {
    FOOTNOTE: '* All prices are in {0}',
    PICK_DATE: 'Pick a date',
  },
  REVIEW_LOC: {
    VIEW_ORIGINAL: 'View original review in {0}',
    VIEW_ORIGINAL_NO_LANG: 'This review is translated. Show original review.',
    TRANSLATE: 'Translate review in {0}',
    LANGUAGES: {
      EN: 'English',
      ES: 'Spanish',
      FR: 'French',
      IT: 'Italian',
      DE: 'German',
      PT: 'Portuguese',
      NL: 'Dutch',
    },
  },
  SHOW_PAGE_V2: {
    READ_MORE_REVIEWS: 'Read more reviews',
    SHOW_MORE_REVIEWS: 'Show more reviews',
    MUST_DO_EXP: 'Must-do experience',
    ALL_PHOTOS: 'All photos',
    MORE_DATES: 'More dates',
    SELLING_OUT_FAST: 'Selling out fast',
    SELECT_TIME_SLOT: 'Select a time slot',
    SELECT_TIME_SLOT_ERROR: 'Select a time slot to continue',
    PRICE_STARTING_FROM: 'Seats price starting from',
    SELECT_SEATS: 'Select seats',
    READ_MORE: 'Read more',
    RATINGS_AND_REVIEWS: 'Ratings & reviews',
    READ_DETAILED_REVIEWS: 'Read detailed reviews',
    INTERVAL: 'Interval',
    TWO_PART_SHOW: 'Two-part show',
    UNTIL_DATE: 'Until {0}',
    AVAILABLE_TIME: 'Available time slot',
    EXPERIENCE_AVAILABLE_ONLY_AT:
      'This experience is available at the below time only',
    CONTENT_TABS: {
      ABOUT: 'About',
      VENUE: 'Venue',
      TICKETS: 'Tickets',
      Reviews: 'Reviews',
    },
    SELECT_SHOW_TIMMING: 'Select your showtime',
    ONLY_ONE_SLOT: 'Only 1 time slot available on the selected date',
    SIMILAR_SHOWS: 'You might also like',
    CONTENT_SECTION_HEADERS: {
      STORYLINE: 'Storyline',
      WHAT_CRITICS_THINK: 'What the critics think',
      FREQUENTLY_ASKED_QUESTIONS_ABOUT: 'Frequently asked questions about {0}',
      AGE_SUITABILITY_AND_GUIDELINES: 'Age & content guide',
      CAST_AND_CREATIVES: 'Cast & creatives',
    },
    TICKETS_UNAVAILABLE: 'Tickets unavailable',
    TICKETS_UNAVAILABLE_SUBTEXT:
      'Luckily, we have plenty more to choose from right here.',
    MUST_SEE_SHOWS: 'More must-see shows',
    SEE_ALL_SHOWS: 'See all shows',
  },
  HOHO: {
    HOHO: 'Hop On Hop Off Tours',
    BUS_ROUTES_DETAILS: 'Bus routes',
    TOUR_DETAILS: 'Tour details',
    ROUTES_SCHEDULES: 'Routes & schedules',
    VALIDITY: 'Valid for 1 day',
    VALIDITY_NOTE:
      'Your experience is valid for {0} calendar day(s). If you book your ticket for 6th May, 2023- your ticket will be valid till 6th May 2023, 9:00 PM PST.',
    BESTSELLER: 'Bestseller',
    SEE_ALL: 'See all',
    TIMINGS: 'TIMINGS',
    FREQUENCY: 'FREQUENCY',
    DURATION: 'DURATION',
    STARTING_LOCATION: 'Starting location',
    CALENDAR_FOOTNOTE: '* All prices are in {0}',
    TOUR_TIMINGS: 'Tour timings',
    TOUR_FREQUENCY: 'Tour frequency',
    GOT_IT: 'Got it',
    SELECT_DATE: 'Select a date',
    SELECT_TOUR: 'Select tour type',
    VIEW_TOUR_DETAILS: 'View tour details',
    BUS_DETAILS: 'MORE DETAILS',
    VIEW_ROUTES: 'View all routes',
    BUS_ROUTES: 'BUS ROUTES',
    ATTRACTIONS_PREVIEW: 'Fastest way to view {0}+ top attractions including',
    ROUTES: 'Routes',
    COMBO_SUBTITLE: 'Save more with',
    COMBO_TITLE: 'Combo deals on your Hop-on Hop-off tour',
    COMBO_DWEB_TITLE: 'Save more with combo deals on your Hop-on Hop-off tour',
    STOPS_AND_ATTRACTIONS: 'Stops & nearby attractions',
    HOP_ON_OFF_AT: 'Hop on and hop off at any of these {0} stops',
    TIMINGS_FREQUENCY: 'Timings & frequency',
    TOUR_DURATION: 'Tour duration',
    EVERY_X_MINS: 'Every {0}',
    VIEW_LOCATION: 'View location',
    BANNER_SUBTEXT:
      'Most convenient and quickest way to see all top attractions in the city.',
    TOP_ATTRACTIONS: 'Top attractions covered',
    TRUST_BOOSTERS: {
      LAST_MIN: {
        NAME: 'Last minute booking',
        DESCRIPTION: 'Instantly confirmed to your phone',
      },
      COST_EFF: {
        NAME: 'Cost-effective exploring',
        DESCRIPTION: 'Multiple landmarks, one ticket',
      },
      DAILY_RIDES: {
        NAME: 'Daily unlimited rides',
        DESCRIPTION: 'Explore the city at your pace',
      },
      BOOK_RELAX: {
        NAME: 'Book and relax',
        DESCRIPTION: 'Trusted by {0} million guests',
      },
    },
  },
  BANNER_DESCRIPTORS: {
    EXPERT_MULTILINGUAL_GUIDES: 'Expert multilingual guides',
    STL: 'Skip-the-line access',
    PERSONALIZED: 'Personalized experience',
    LOCAL_FOOD: 'Local food & culture',
    MULTILINGUAL_GUIDES: 'Multilingual guides',
    CULINARY_EXPERTS: 'Culinary experts',
    SIGHTSEEING: 'Sightseeing & onboard entertainment',
    SIGHTSEEING_MEAL: 'Sightseeing & onboard meal options',
    MULTILINGUAL_GT: 'Multilingual guided tours',
    AUDIOGUIDE_COMMENTARY: 'Audioguide & commentary available',
    ALL_TOP_ATTRACTIONS_COVERED: 'All top attractions covered',
    REG_SCHEDULE_UNLIMITED_RIDES: 'Regular schedule. Unlimited rides',
    LAST_MIN_AVAIL_INSTANT_CONFIRMATION:
      'Last minute availability. Instant confirmation',
    MULTI_ROUTES: 'Multiple route options',
    HASSLE_FREE: 'Hassle-free booking',
    FREQUENT_DEPARTURES: 'Frequent departures',
    ONBOARD_AMENITIES: 'Best onboard amenities',
    FLEXI_TRAVEL: 'Flexible travel on multiple routes',
    FLEXI_EXCHANGE_CANCEL: 'Flexible exchanges and cancellations',
    EXTRA_DISCOUNT: 'Extra discounts for youths, seniors & families',
    PREPAID: 'Prepaid convenience',
    EASY_ACTIVATION: 'Easy activation',
    INSTANT_CONNECTIVITY: 'Instant connectivity with global coverage',
    ROUND_TRIP: 'Round-trip transfers',
    CULTURAL_EXPERIENCE: 'Authentic cultural experiences',
    GUARANTEED_SEAT: 'Guaranteed seat',
    ONBOARD_WIFI: 'Onboard Wi-Fi & charging ports',
    LUGGAGE_ASSISTANCE: 'Luggage assistance',
    TOP_ATTRACTIONS: 'Top attractions included',
    FREQ_DEP_MULTI_ROUTE: 'Frequent departures on multiple routes',
    CHARGING: 'Charging station',
    WIFI: 'Wi-Fi access',
    FnB: 'F&B availability',
    LUXURY: 'Luxury experience',
    FOOD_DRINK: 'Food & drinks',
    PANAROMIC: 'Panoramic views of top attractions',
    PANAROMIC_2: 'Panoramic views',
    PANAROMIC_3: 'Panoramic, open-top views',
    BEVERAGES: 'Onboard beverages',
    GOURMET: 'Gourmet dinner',
    GOURMET_2: 'Gourmet dinner & drinks',
    NIGHTTIME: 'Stunning nighttime views',
    ROMANTIC: 'Romantic atmosphere',
    TRANSFERS: 'Transfers included',
    CERTIFIED_OP: 'Certified operations',
    BIRD_EYE: "Bird's-eye view",
    MULTI_JUMP: 'Multiple jump times',
    EXTREME_ADV: 'Extreme adventure',
    PHOTO_OP: 'Scenic photo opportunities',
    TOP_ATTRACTIONS_COVERED: 'Top attractions covered',
    LOCAL_CULTURE: 'Experience local culture',
    EXCLUSIVE_DISC: 'Exclusive discounts',
    EFFICIENT_PUBLIC_TRANSIT: 'Efficient public transit',
    FREQUENT_SERVICE: 'Frequent service',
    PREBOOKING: 'Easy pre-booking',
    LOCAL_CULTURE_IMM: 'Local cultural immersion',
    DISCOVER_HIDDEN: 'Discover hidden places',
    CITY_SIGHTSEEING: 'City sightseeing',
    OFFBEAT_ADV: 'Offbeat adventure',
    DRINK_DEALS: 'Drink deals',
    OFFBEAT_EXP: 'Offbeat experience',
    MULTIPLE_VARIETIES: 'Multiple varieties included',
    HIDDEN_GEMS: 'Hidden gems & offbeat attractions',
    EXPLORE_LOCAL: 'Explore local neighbourhoods',
    CUSTOMIZED_TREATMENTS: 'Customized treatments',
    TRAINED_THERAPISTS: 'Trained therapists',
    HOLISTIC_WELLNESS: 'Holistic wellness',
    FLEXIBLE_DURATION: 'Flexible duration',
    MULTI_BOAT: 'Multiple boat options',
    SCENIC_ROUTES: 'Scenic routes',
    HANDS_ON: 'Hands-on learning',
    CULTURAL_CULINARY: 'Cultural & culinary insights',
    EXPERT_HOSTS: 'Expert hosts',
    ADVENTURE_EXPERIENCE: 'Adventure experience',
    EQUIPMENT: 'Equipment included',
    SCENIC_FLYOVERS: 'Scenic flyovers',
    LIVE_COMMENTARY: 'Live commentary',
    BEST_AERIAL_VIEWS: 'Best aerial views',
    SAFE_CERT_OP: 'Safe & certified operations',
    HANDPICKED: 'Handpicked tours',
    EFFORTLESS: 'Effortless planning',
    EXPERT_GUIDES: 'Expert guides. Unique insights',
    ALL_ATTR_ONE_CARD: 'All attractions, one card',
    MULTI_CARD: 'Multiple card options',
    COST_EFF: 'Cost-effective and convenient',
    BEST_PRICES: 'Best prices',
    MULTI_TRANSPORT: 'Multiple transport options',
  },

  CAT_SUBCAT_PAGE: {
    ALL: 'All',
    POPULAR_SUBCATEGORY: 'Popular {0}',
    TOP_CATEGORY: 'Top {0}',
    ATTRACTIONS: 'Attractions',
    ALL_SUBCATEGORY: 'All {0}',
    BROWSE_BY_CATEGORIES: 'Browse by categories',
    TICKETS_FROM: 'Tickets from',
    PRICE_LOW_HIGH: 'Price (low to high)',
    PRICE_HIGH_LOW: 'Price (high to low)',
    FILTERS: 'Filters',
    RESET: 'Reset',
    APPLY: 'Apply',
  },
  COOKIE_BANNER: {
    DESKTOP:
      'Headout uses cookies to enhance your website journey one byte at a time.',
    MOBILE: 'Cookies at work.',
    LEARN_MORE: 'Learn more.',
  },
  SHOW_UNAVAILABLE: 'Show unavailable',
  NEWS_PAGE: {
    FEATURED_NEWS: 'Featured news',
    ALL_NEWS: 'All news',
    MORE_READS: 'More reads',
    LOAD_MORE: 'Load more',
    TRAILERS: 'Trailers',
    GET_YOUR_TICKETS: 'Get your tickets',
    POPULAR_SHOWS: 'Popular shows',
    FEATURED: 'Featured',
    RECENT_NEWS: 'Recent news',
    SHOW_MORE_ARTICLES: 'Show more articles',
    COLLAPSE_ALL: 'Collapse all',
    REVIEWS: 'Reviews',
    SHOW_ALL_REVIEWS: 'Show all reviews',
    MAILER: {
      HEADING: 'Best theatre deals & news straight to your inbox',
      SUBHEADING: `Join our mailing list to get the latest news & hottest deals on theatre shows.`,
    },
  },
  REVIEWS_PAGE: {
    BANNER_HEADING: '{0} reviews',
    REVIEWS_COUNT: '{0} reviews',
    AWESOME: 'Awesome',
    GOOD: 'Good',
    AVERAGE: 'Average',
    POOR: 'Poor',
    TERRIBLE: 'Terrible',
    REVIEW_BY_HEADOUT: 'Here’s what we think: Review by Headout',
    WHAT_PEOPLE_ARE_SAYING: 'What people are saying',
    MOST_RELEVANT: 'Most relevant',
    RATING_HIGH_TO_LOW: 'Rating: High to Low',
    RATING_LOW_TO_HIGH: 'Rating: Low to High',
    CRITIC_REVIEWS: 'Critic reviews',
    USER_REVIEWS: 'User reviews',
    CONTRIBUTORS_REVIEW: 'Contributor’s review',
  },

  AIRPORT_TRANSFER: {
    PRIVATE_TRANSFERS: 'Private transfers',
    SHARED_TRANSFERS: 'Shared transfers',
    BOOKING_STEPS: {
      BOOK_ONLINE: {
        TITLE: 'Book online',
        DESCRIPTION:
          'Enjoy a fast, and convenient booking experience by booking your tickets online.',
      },
      INSTANT_CONFIRMATION: {
        TITLE: 'Instant confirmation',
        DESCRIPTION:
          'Get instant confirmation on booking your airport transfer ticket.',
      },
      CONVENIENT_PICKUP: {
        TITLE: 'Convenient pickup',
        DESCRIPTION:
          'Avoid the hassle of long queues as your transfer vehicle will be waiting for you at your selected pickup point.',
      },
      DROP_OFF: {
        TITLE: 'Drop off',
        DESCRIPTION:
          'Get to reach your destination in a fast and comfortable manner.',
      },
    },

    SHARED_TRANSFERS_IN: 'Shared transfers in {0}',
    SHARED_TRANSFERS_DESCRIPTION:
      ' A comfortable cost-effective transportation option in for multiple passengers traveling to or from an airport.',
    PRIVATE_TRANSFERS_IN: 'Private transfers in {0}',
    PRIVATE_TRANSFERS_DESCRIPTION:
      ' A convenient, personalized transportation service for individuals or small groups, offering a comfortable and direct ride to and from an airport.',
    ANYWHERE_IN_THE_CITY: 'Anywhere in the city',
    DESCRIPTORS: {
      MULTIPLE_VEHICLE: 'Multiple vehicle options',
      FREE_CANCELLATION: 'Free cancellation',
    },
    SEAMLESS_TRANSFERS: 'Seamless transfers, trusted worldwide',
    PICK_THE_BEST_AIRPORT_TRANSFER: 'Pick the best airport transfer for you',
    HASSLE_FREE_TRANSFER: 'Hassle-free airport transfer experience',

    // New experiment (AT LP)
    EFFORT_LESS_AIRPORT_TRANSFERS: 'Effortless airport transfers in {0} by',
    BUSES: 'buses',
    TRAINS: 'trains',
    PRIVATE_TAXIS: 'private taxis',
    BUS_TRAIN: 'Bus/Train',
    PRIVATE_TAXI: 'Private taxi',

    FROM: 'From',
    SELECT_PICKUP: 'Select pick-up',
    TO: 'To',
    SELECT_DROPOFF: 'Select drop-off',
    ENTER_DESTINATION: 'Enter your destination',
    ENTER_PICKUP: 'Enter pick-up location',
    PICKUP_DATE: 'Pick-up date',
    SELECT_DATE: 'Select date',
    PICKUP_TIME: 'Pick-up time',
    SELECT_TIME: 'Select time',
    GUEST: 'Guest',
    GUESTS: 'Guests',
    ADD_GUESTS: 'Add guests',
    EXPLORE_TRANSFERS: 'Explore transfers and more',
    COMBOS_AND_EXTRAS: 'Combos and extras',

    YOUR_RIDE_DOORSTEP: 'Your ride, at your doorstep',
    COMFORTABLE_RIDE:
      'Book a comfortable private taxi to and from major airports in {0}',
    TRUSTED_DRIVERS: 'Trusted drivers',
    RIDES_24_7: 'Rides available 24/7',
    FLIGHT_TRACKING: 'Flight tracking',
    KNOW_MORE: 'Know more',
    RESERVE_YOUR_RIDE: 'Reserve your ride',
    TRANSFER_OPTIONS: 'Transfer options found',
    TRANSFERS_AVAILABLE_PLURAL: '{0} transfers available',
    TRANSFERS_AVAILABLE_SINGULAR: '1 transfer available',

    TRUSTED_PARTNERS: '35+ trusted partners, {0}',
    WORLDWIDE: 'worldwide',
    COMFORTABLE_RIDES: 'Comfortable rides',
    FREQUENT_DEPARTURES: 'Frequent departures',
    MULTIPLE_TRANSPORT_OPTIONS: 'Multiple transport options',
    AND_MANY_MORE: 'And many more',

    ADD_PICKUP_LOCATION: 'Add pick-up location',
    ADD_DROPOFF_LOCATION: 'Add drop-off location',
    SELECT_PICKUP_DATE_AND_TIME: 'Select pick-up date and time',
    SELECT_A_DATE: 'Select a date',

    TRAIN_STATION: 'Train station',
    BUS_STOP: 'Bus stop',

    ADD_RETURN: 'Add return',
    HEADS_UP: 'Heads up!',
    ADDED_RETURN_TRIP: 'You’ve added a return trip!',
    RETURN_NOTE:
      'Please note, we’ll show the return journey details in the next steps.',
    OKAY_I_UNDERSTAND: 'Okay, I understand',

    SERVING_GUESTS: 'Serving {0} in {1} around the globe',
    GUESTS_COUNT: '220K+ guests',
    CITIES_COUNT: '55+ cities',
    PARNTER_WITH_BEST:
      'We partner with only the best, to make sure you have an unforgettable experience. Let us handle the details — while you sit back, unwind, and enjoy the ride',
  },
  PC_EXP: {
    SHOW_INCL: 'Show inclusions & important info',
    CLEAR: 'Clear',
    ALL_EXPERIENCES: 'All experiences',
    FILTER_BY_CATEGORIES: 'Filter by categories',
    CATEGORIES: 'Categories',
    COMBOS: {
      HEADING: 'Everything good comes in pairs. Just like our saver combos.',
      DESCRIPTOR_1: 'Money saving deals',
      DESCRIPTOR_2: 'Plan your trip better',
    },
  },
  TOP: 'Top {0}',
  VIEW_CALENDAR: 'View calendar',
  SHOWS_CALENDAR: 'Shows calendar',
  IN: 'in',
  SEATS: 'Seats',
  CONTENT_PAGE: {
    CONTENT_GUIDE: 'Content Guide',
    QUICK_INFORMATION: 'Quick Information',
    PEAK: 'Peak',
    OFF_PEAK: 'Off Peak',
    ADDRESS: 'ADDRESS',
    RECOMMENDED_DURATION: 'RECOMMENDED DURATION',
    VISITORS_PER_YEAR: 'VISITORS PER YEAR',
    NUMBER_OF_ENTRANCES: 'NUMBER OF ENTRANCES',
    EXPECTED_WAIT_TIME_STANDARD: 'EXPECTED WAIT TIME - STANDARD',
    EXPECTED_WAIT_TIME_SKIP_THE_LINE: 'EXPECTED WAIT TIME - SKIP THE LINE',
    UNESCO_YEAR: 'UNESCO YEAR',
    ARCHITECTURE_STYLE: 'ARCHITECTURE STYLE',
    TIMINGS: 'Timings',
    SIZE_HEIGHT: 'SIZE/HEIGHT',
    TICKETS: 'TICKETS',
    FUN_FACTS: 'Fun facts',
    DID_YOU_KNOW: 'Did you know?',
    MORE_INTERESTING_FACTS: 'More interesting facts',
    PLAN_YOUR_VISIT: 'Plan your visit',
    ALSO_KNOWN_AS: 'Also Known As',
    FOUNDED_ON: 'Founded On',
    FOUNDED_BY: 'Founded By',
    FROM: 'From',
    CLOSED_TODAY: 'Closed today',
    TODAY: 'Today',
    CLOSED: 'Closed',
    OPEN: 'Open',
    LAST_ADMISSION: 'Last Admission',
    DAYS: 'Days',
    BEST_TIME_TO_VISIT: 'Best time to visit the',
    WEEKDAY_VS_WEEKEND: 'Weekday vs Weekend',
    PEAK_VS_LOW_SEASON: 'Peak season vs low season',
    PLEASANT: 'Pleasant',
    CROWDED: 'Crowded',
    VERY_CROWDED: 'Very Crowded',
    LOW_SEASON: 'Off Season',
    MID_SEASON: 'Moderate Season',
    PEAK_SEASON: 'Peak Season',
    TO: 'TO',
    LOWER_TO: 'to',
    LAST_ENTRY: 'last entry',
    SUBATTRACTIONS: 'Sub-Attractions',
    INCLUDED_WITH_TICKETS: 'Included with {0} tickets',
    INCLUDED_WITH_SOME_TICKETS: 'Included with some {0} tickets',
    EXPLORE_ALL_POI_TICKETS: 'Explore {0} & {1} tickets & tours',
    EXPLORE_PARENT_TICKETS: 'Explore {0} tickets & tours',
    SELECT_YOUR_EXPERIENCE: 'Select your experience',
    OPERATING_HOURS: 'Operating hours',
    SEE_MORE: 'See more',
    MORE_INSIGHTS: 'More insights',
  },
  OFF_PERCENT: '{0}% off',
  THEATRE_LANDING_PAGE: {
    SEAT_PLAN: 'Seat plan',
    CAPACITY: 'Capacity: {0} seats',
    WHATS_ON: `What's on`,
    CATEGORIES: 'Categories',
  },
  NEXT: 'Next',
  PREVIOUS: 'Previous',
  ITINERARY: {
    TAB: 'Itinerary',
    HEADING: 'Experience itinerary',
    VIEW_ITINERARY: 'View Itinerary',
    VIEW_DETAILS: 'View details',
    PASSES_BY_SECTION_HEADING: 'Passes by',
    SUB_SECTION_HEADING: {
      HIGHLIGHTS: 'Highlights',
      NEARBY_THINGS_TO_DO: 'Nearby things to do',
      THINGS_TO_DO: 'Things to do',
    },
    STOP_CARD: {
      TITLE: {
        START: 'START POINT',
        END: 'END POINT',
        STARTING_POINT: 'STARTING POINT',
        STOP: 'STOP',
        ENDING_POINT: 'ENDING POINT',
        PASSING_BY: 'PASSING BY',
      },
      MULTI_POINTS_AVAILABLE: {
        START: '{0} starting points available',
        END: '{0} ending points available',
      },
    },
    GET_DIRECTION: 'Get direction',
    DESCRIPTORS: {
      DURATION: {
        FULL: '{0} hours {1} mins',
        WITHOUT_HOURS: '{0} mins',
        WITHOUT_MINS: '{0} hours',
      },
      ATTRACTIONS_COUNT: '{0} attractions',
      ACTIVITIES_COUNT: '{0} activities',
      TOTAL_DURATION: 'TOTAL DURATION',
      FREQUENCY: 'FREQUENCY',
      FIRST_DEPARTURE: 'FIRST DEPARTURE',
      LAST_DEPARTURE: 'LAST DEPARTURE',
      FIRST_DEPARTURE_TIME: 'FIRST DEPARTURE TIME',
      LAST_DEPARTURE_TIME: 'LAST DEPARTURE TIME',
      MODE_OF_TRANSPORT: 'MODE OF TRANSFER',
      FOOD_AND_DRINKS: 'FOOD & DRINKS',
    },
    WALK_DURATION: '{0} walk',
    MORE: '{0} more',
    TIMELINE_VIEW: 'Timeline',
    MAP_VIEW: 'Map',
    MAP_OVERLAY: 'Use CTRL/⌘ + scroll to move the map',
    VIEW_EXPERIENCE_MAPPED_OUT: 'View your experience all mapped out.',
    OPEN_MAP_VIEW: 'Open map',
    START_POINT_SAME_AS_END_POINT:
      'Your ending point would be same as your start point',
  },
  CRUISES: {
    BANNER_HEADING: 'Cruise Through {0} - Discover, Dine & Relax ',
    FILTERS: {
      ALL: 'All',
      SIGHTSEEING: 'Sightseeing',
      LUNCH: 'Lunch',
      DINNER: 'Dinner',
      COMBOS: 'Combos',
    },
    MAP_OVERLAY: 'Click or tap to move around the map',
    SIGHTS_COVERED: 'Sights covered',
    MENU: 'Menu',
    BOARD_AT: 'Board at {0}',
    BOARDING_POINTS_AVAILABLE: '{0} available',
    VIEW_POPULAR_SIGHTS: 'View popular sights like {0} {1}',
    X_BOARDING_POINTS: '{0} boarding points',
    BOARDING_POINT: 'Boarding point',
    BOARDING_POINTS: 'Boarding points',
    LANDMARKS: 'Landmarks',
    GET_DIRECTIONS: 'Get directions',
    FOOD_MENU: 'Food menu',
    X_PAGE: '{0} page',
    X_PAGES: '{0} pages',
    MORE: '+{0} more',
    PDF: {
      ZOOM_IN: 'Zoom in',
      ZOOM_OUT: 'Zoom out',
      FIT_TO_WIDTH: 'Fit to width',
      RESET_ZOOM: 'Reset zoom',
    },
    COMBO_HEADING: 'Experience more for less: Combo deals & city cards',
  },
  CUSTOM_CTA_EXPERIMENT_TEXT: 'Check availability', // just for types
  SELECT_AN_OPTION: 'See options',

  PRIVATE_AT_LANDING_PAGE: {
    CITY_AIRPORT_TRANSFERS: '{0} Airport Transfers',
    BANNER_SUBTEXT:
      'Enjoy stress-free travel with a reliable, pre-booked airport transfer.',
    BEST_PRICES: 'Best prices, no hidden fees',
    BEST_PRICES_SUBTEXT:
      'Fixed pricing with no surprises, offering you competitive rates for premium service.',
    FREE_WAIT_TIME: 'Free wait time, no rush',
    FREE_WAIT_TIME_SUBTEXT:
      'Enjoy a free waiting time of 45 minutes while you claim your baggage',

    COMFORTABLE_RIDE: 'Comfortable ride',
    COMFORTABLE_RIDE_SUBTEXT:
      'Ride in style with a fleet of top-class vehicles, driven by handpicked and trained drivers.',

    FREE_CANCELLATION: 'Free cancellation',
    FREE_CANCELLATION_SUBTEXT:
      'Plans changed? No problem. Enjoy free cancellation and 24/7 support.',

    EXCELLENCE_HEADING: 'A track record of excellence and trust',
    ACTIVE_CITIES: 'active cities, worldwide',
    CUSTOMERS_SERVED: 'customers served',
    YEARS_OF_SERVICE: 'years of excellent service',

    FIND_YOUR_RIDE: 'Find your perfect ride',
    FIND_YOUR_RIDE_SUBTEXT:
      'From family-sized vans to luxury sedans, pick a car that fits your luggage and group size',
  },
  VIEW_ALL_EXPERIENCES: 'View all experiences',
  DAY_TRIPS: {
    BANNER: {
      SUBTEXT: `Skip the stress of planning. We cover every detail - curated itinerary, comfortable transfers and expert guides. It's the best way to see the sights without the hassle.`,
    },
    WHY_WITH_HO: {
      TITLE: 'Book stress free with Headout',
      CURATED_EXPERIENCES: {
        TITLE: 'Simple decision making with carefully chosen experiences',
        DESCRIPTION:
          'We handle every detail from transfers to meals so you have a memorable trip.',
      },
      EXPERT_GUIDES: {
        TITLE: 'Experience the trip through the eyes of a local expert',
        DESCRIPTION:
          'Our guides bring rich insights and memorable stories to every journey.',
      },
      FLEXI_CANCELLATION: {
        TITLE: 'Enjoy peace of mind with maximum flexibility ',
        DESCRIPTION:
          'Life happens, but we’ve got you covered with free cancellations or easy reschedule.',
      },
      GUEST_SUPPORT: {
        TITLE: '24x7 support available for you at all times',
        DESCRIPTION:
          'Travel with peace of mind knowing that our team is just a call or message away.',
      },
    },
    JUMP_LINK_ITEMS: {
      TESTIMONIALS: {
        TITLE: '{0}M+ travellers love us',
        SUBTITLE_WITH_REVIEWS: 'Read real reviews from real travellers',
        SUBTITLE_WITHOUT_REVIEWS: 'Delivering best in class experience',
      },
      HEADOUT_VERIFIED: {
        TITLE: 'Why book with us?',
        SUBTITLE: 'Hassle-free trips. Find out how.',
      },
    },
    NEAR_BY_DESTINATIONS_TITLE: 'Explore more destinations from {0}',
  },
  REVIEW_SECTION: {
    HEADING: 'Millions love heading out with us',
    SORTING: {
      MOST_RELEVANT: 'Most relevant',
      MOST_RECENT: 'Most recent',
      SORT_BY: 'Sort by',
    },
    FILTERING: {
      GREATER_THAN_4: '4+ stars',
      EQUAL_TO_3: '3 stars',
      LESS_THAN_3: '<3 stars',
      WITH_IMAGES: 'With images',
    },
    EMPTY_STATE: {
      SUB_HEADING: "We couldn't find reviews matching your selected filters.",
      CTA: 'Show all reviews',
    },
    VERIFIED_REVIEW: 'Verified Partner Review',
  },
  FLEXIBLE_CANCELLATION: {
    TOOLTIP_TEXT:
      'Get full refund for a small fee if you cancel 24 hours before the experience start time.',
    KNOW_MORE: 'Know more',
    FULL_REFUND: 'Full refund',
    INSTANT: 'Instant',
    NO_QUESTIONS: 'No questions asked',
    TNC: 'Terms and conditions',
    ELIGIBILITY:
      'Eligibility: The Flexible cancellation plan must be selected and purchased at the time of booking.',
    CANCELLATION_DEADLINE:
      'Cancellation deadline: Booking can be cancelled up to 24 hours before the experience start time.',
    REFUND_AMOUNT:
      'Refund amount: You’ll receive a refund of the original paid booking amount (minus the cancellation protection charge). ',
    NON_TRANSFERABLE: 'Non-Transferable: Valid for this booking only.',
    GUIDELINES:
      'Guidelines: This plan becomes void if the guest redeems or uses any part of the ticket before cancellation. In such cases, standard cancellation policies apply, and no additional refund will be given.',
  },
  AGGREGATED_COUNTRIES:
    'Guests from <strong>{0}</strong> and <strong>over {1} countries</strong> have loved this experience',
  TRUST_TOOLTIP_HEADER: 'How do we collect reviews?',
  TRUST_TOOLTIP_CONTENT:
    "These ratings include verified reviews from both Headout guests and our trusted partners who operate this experience locally. All reviews come from real travelers who've taken this experience.",
  REVIEW_SECTION_HEADER: 'What our guests say',
  TRUST_VB_TAG: 'Verified booking',
  SNAPSHOTS_SECTION_HEADER: 'Snapshots from our guests',
  SEE_MORE_RESPONSES: 'See more responses',
  SEE_ALL_RESPONSES: 'See all responses',
  HIDE_RESPONSES: 'Hide responses',
  GUEST_QNA: 'Guest Q&A',
  ASKED_AND_ANSWERED: 'Asked & answered',
  ADVICE_FROM_TRAVELLERS: 'Advice from {0} travelers',
  VIEW_ALL_QNA: 'View all Q/As',
  EXPLORE_ALL: 'Explore all',
  EXPLORE_MORE_QUESTIONS: 'Explore more questions',
  GUESTS: 'guests',
  RESPONSES: 'responses',
  ADVICE: 'Advice from {0} travelers',
  REAL_TIPS:
    'Real tips from real people who’ve been there, <span>done that!<span>',
  GENUINE_TIPS: 'Genuine tips from those who’ve been there',
  ANSWERS_COUNT: '{0} answers',
  SEE_ALL_QNA: 'See all Q&A',

  FILTERS: {
    DATE_SELECTION: 'Date selection',
    DEALS: 'Deals',
    ENTRY_TICKETS: 'Entry tickets',
  },

  BOOSTERS: {
    MOST_LOVED: 'Most loved',
    SPECIAL_DEAL: 'Special deal',
    HEADOUT_EXCLUSIVE: 'Headout Exclusive',
  },

  OTHER_EXPERIENCES: 'Other experiences',

  POI_COLLECTIONS_SECTION: {
    MAKE_THE_MOST_OF_CITY: 'Make the most of {0}',
    TOP_EXPERIENCE_PICKS: 'Top experience picks',
    EXPERIENCES: '{0}+ experiences',
    CITY_YOUR_WAY: '{0}, your way!',
    DISCOVER_MORE: 'Discover more',
  },
  DROPS: {
    DISCOUNT_TAG: 'Up to 80% off with',
    TITLE: 'Epic experiences at just AED 49',
    SUBTITLE:
      'Participate and win steal deals for Dubai Dolphinarium, Miracle Garden, Yacht Cruises, and much more.',
    CTA_BUTTON: 'Tell me more',
    CTA_BUTTON_MOBILE: 'Download the Headout app',
    DOWNLOAD_APP_NUDGE: {
      TITLE: 'HOW TO PARTICIPATE AND WIN A DROP',
      STEP_1: 'Download the Headout app',
      STEP_2: 'Join daily at',
      STEP_2_HIGHLIGHT: '5pm,',
      STEP_2_PART_1: 'win your experience',
      STEP_3: 'Book now, travel anytime',
      QR_SCAN_TEXT: 'Scan QR to download',
    },
    EXIT_INTENT: {
      TITLE: 'Walk away now, pay more later. Grab AED 49 deals on the app',
      DESCRIPTION:
        'Participate and win steal deals for Dubai Dolphinarium, Miracle Garden, Yacht Cruises, and much more.',
      CTA_TEXT: 'Explore Deals',
      DOWNLOAD_APP: 'Download the Headout app',
      NOT_NOW: 'Not now',
    },
  },
  PRIVACY_POLICY_CONTENT: {
    title: 'Headout: Privacy Policy',
    lastUpdated: '31st March 2025',
    sections: [
      {
        title: 'Why this Privacy Policy?',
        content: [
          {
            type: 'list',
            items: [
              {
                text: 'Headout is vigilant and committed towards the protection and management of Your Personal Information. Thus, Headout has implemented this Privacy Policy that aims to safeguard Your Personal Information.',
              },
              {
                text: 'This Policy, describes inter alia Headout\'s procedures for the collection, processing, disclosure and protection of Your Personal Information when You i.) as a user access our website www.headout.com or its mobile application ("Platform"), or ii.) as Supply Partner access https://hub.headout.com/ ("Headout Hub") or iii.) as an Affiliate Partner access https://partner.headout.com/affiliate/ ("Headout Partner Platform") or iv.) as Creator Partner access our creator site ("Creator Platform") [Platform, Supply Partner Platform, Affiliate Partner Platform and Creator Platform shall together be referred as "Headout Platform"]. This Policy also intended to guide You about Your privacy rights. Under this Policy, we have also suggested how You can contact Us in case You have any issues/doubts.',
              },
              {
                text: 'Please note that we use Your Personal Information to inter alia provide You with the service(s) available on the Headout Platform in the best possible manner, improvise the overall usage of the Headout Platform and to carry out other processing activities outlined in this Policy.',
              },
              {
                text: "This Privacy Policy shall not be applicable to third-party websites/apps that may be linked to Headout Platform. Users are requested to refer to respective third-party websites/apps' privacy policies to understand how their Personal Information will be collected and processed by such websites/apps.",
              },
              {
                text: 'This Privacy Policy shall be read along with the Terms of Use, Supply Partner Agreement, Affiliate Partner Terms and Creator Terms and all capitalized terms used, but not defined herein, shall have the respective meanings as ascribed to them in the aforesaid different terms. In case of any overlap or non-alignment in terms of interpretation, final decision shall always be taken by Headout and You shall not interpret the terms as per your own convenience.',
              },
            ],
          },
        ],
      },
      {
        title: 'How are You bound by the terms of this Privacy Policy?',
        content: [
          {
            type: 'list',
            items: [
              {
                text: 'By using or accessing the Headout Platform, You agree to the collection and use/processing of Personal Information in accordance with this Privacy Policy. This collection of information is necessary to provide the services on Headout Platform. In case of any doubt, reach out to us on privacy@headout.com.',
              },
            ],
          },
        ],
      },
      {
        title: 'Terms referred to in Privacy Policy',
        content: [
          {
            type: 'paragraph',
            items: [
              {
                text: 'For the purposes of this Privacy Policy:',
              },
            ],
          },
          {
            type: 'list',
            items: [
              {
                text: 'Account means a unique account created for You on the Headout Platform to access Our service or parts of Our service.',
              },
              {
                text: 'Company (referred to as either "Headout", "the Company", "We", "Us" or "Our" in this Agreement) refers to Headout. Inc., its affiliates, subsidiaries and/or any other body corporate related to Headout.',
              },
              {
                text: 'Cookies are small files that are placed on Your computer, mobile device, or any other device by Headout Platform, containing the details of Your browsing history among its many uses.',
              },
              {
                text: 'Device means any device that can be used to access the Headout Platform such as a computer, a cellphone, or a digital tablet.',
              },
              {
                text: 'Personal Information is any information that relates to You and helps in your identification. Personal Information is shared by You while availing services available on the Headout Platform. Headout collects this from You to provide You with services available on the Headout Platform and to fulfil its different legal obligations. Please refer to pointer 5 (What type of information is collected by Headout?) for detailed information.',
              },
              {
                text: 'Privacy Policy/Policy means and includes this document and amendments that may be made to this document in future. This shall also include any other document that Headout may come up with in future around this policy.',
              },
              {
                text: 'Social Media Platforms refer to platforms like Facebook, Instagram etc. that are used to communicate with the Company in different scenarios and/or to create a login account on the Headout Platform.',
              },
              {
                text: 'Service Providers refers to the sellers/supply partners listed on Headout Platform including affiliates and other business partners.',
              },
              {
                text: 'Third Party Vendors refers to third-party companies or individuals that may be engaged by the Company for the smooth and error-free performance of the Headout Platform. These third parties may also be engaged by the Company for better facilitation of services available on the Headout Platform which includes analysing Your usage of the Headout Platform.',
              },
              {
                text: 'User refers to any person who visits/shows interest/avails any Service available on Platform',
              },
              {
                text: 'Website refers to www.headout.com',
              },
              {
                text: 'You/Your shall mean the User, Service Provider and Creator Partner',
              },
            ],
          },
        ],
      },
      {
        title: 'Why does Headout collect Your Personal Information?',
        content: [
          {
            type: 'list',
            items: [
              {
                text: 'The Personal Information and other related information are collected by Us to inter alia help Us provide You the services available on the Headout Platform and also to carry out other processing activities outlined in this Policy.',
              },
            ],
          },
        ],
      },
      {
        title: 'What type of information is collected by Headout?',
        content: [
          {
            type: 'list',
            items: [
              {
                text: 'Personal Information:',
                subItems: [
                  {
                    text: 'The Personal Information that We collect about You depends on various variables including but not limited to the context of Your interactions with Us, the products, services, and features that You use, Your location, and the applicable laws. The Personal Information collected by us can be divided into two heads i.e. Non-Identifiable Personal Information and Personally Identifiable Information.',
                  },
                  {
                    text: '"Non-Identifiable Personal Information" is information that cannot be used to identify You or a person in specific. This data could also be anonymous in nature. This data includes but is not limited to aggregated data around the use of services available on the Platform, masked IP addresses etc.\n"Personally Identifiable Information" is information that is personally identifiable to You and that can be used to distinguish or trace Your identity. This information is also collected by Us in order to provide You with access to certain features of Headout Platform. This information includes but is not limited to information such as name, social security number, date and place of birth, phone number, address, email ID, payment/card details etc.',
                  },
                ],
              },
              {
                text: 'Automated Information:',
                subItems: [
                  {
                    text: 'When You access Headout Platform, some of Your information gets automatically captured. This automated information that We capture falls under the Non-Identifiable Personal Information category. Following are the broad types of information that get automatically captured:',
                    bulletPoints: [
                      'Device information such as the browser used for accessing the Headout Platform, the device used for accessing the Headout Platform, operating system, application version number etc.',
                      'IP information is also captured when You access the Headout Platform.',
                      'Behavioural information i.e. how You are accessing Headout Platform, What different sections of the Headout Platform You access, duration of Your access etc.',
                      'Date and time when You access Headout Platform.',
                    ],
                  },
                  {
                    text: 'All the aforesaid information captured automatically is used for the following purposes:',
                    bulletPoints: [
                      'To ensure the security of Our IT systems, for example, to defend against specific attacks on Our systems and to recognize attack patterns;',
                      'To load balance, i.e., to distribute access to Headout Platform across several devices and to be able to offer You the fastest loading times;',
                      'To understand Your demographics, interests, and behaviour so that the best services can be provided to You;',
                      'in the event of specific indications of criminal offences, to enable criminal prosecution, avoidance of harm, or legal punishment;',
                    ],
                  },
                ],
              },
              {
                text: 'Cookies:',
                subItems: [
                  {
                    text: 'Additionally, We also use different types of cookies to track Your usage of Headout Platform and store certain information that further helps us to provide You with more personalized services. There are certain cookies that may get placed on Your Device because of Your usage of any services available on Headout Platform.',
                  },
                  {
                    text: 'Broadly, We use two different sets of technologies under this head i.e.:',
                    bulletPoints: [
                      'Cookies or Browser Cookies. it is a small file that automatically gets placed on Your Device when You access Headout Platform. You can instruct Your browser to refuse all Cookies or to indicate when a cookie should be saved. In case, if You do not accept Our Cookies, You may not be able to use some parts of Headout Platform.',
                      'Web Beacons. Also referred to as clear gifs, pixel tags, and single-pixel gifs that are attached in various sections of Headout Platform and Our emails that permit us to monitor and understand the activity of Users.',
                    ],
                  },
                  {
                    text: 'These Cookies stored on Your Device can be "Persistent" or "Session" Cookies. Persistent Cookies are the ones that remain on Your Device even when You go offline, whereas, Session Cookies are automatically deleted as soon as You close Your web browser.',
                  },
                  {
                    text: 'We use both Session and Persistent Cookies for the purposes set out below:',
                    bulletPoints: [
                      'Necessary / Essential Cookies\nType: Session Cookies\nPurpose: These Cookies are essential for You to access different sections of the Headout Platform and if these are not allowed You may not be able to access the services available on the Headout Platform. These cookies further help us to authenticate Your identity and prevent any sort of fraudulent activity on the Headout Platform.',
                      'Cookies Policy / Notice Acceptance Cookies\nType: Persistent Cookies\nPurpose: These Cookies identify if You have accepted the use of Cookies.',
                      'Functionality Cookies\nType: Persistent Cookies\nPurpose: These Cookies allow us to remember choices You make when You use the Headout Platform, such as remembering Your login details or language preference. The larger purpose of these Cookies is to provide You with a more personalized experience every time You use the Headout Platform.',
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title:
          'For what do We use Your Personal Information and with whom do We share it?',
        content: [
          {
            type: 'list',
            items: [
              {
                text: 'Your Personal Information collected while You access Headout Platform is used only for lawful purposes and with an aim to provide You the services. We do not sell or rent this information to anyone nor do We share Your information with any third party, unless otherwise provided under the applicable law and specified in this Privacy Policy and/or other than as necessary to fulfill Your request. In case, any of Your information is shared with a third-party in compliance with this Privacy Policy and if they process any of such information, the same will be done in accordance to their respective privacy policies. We request You to refer to the privacy policies of such respective third parties on a pro-active basis.',
              },
              {
                text: 'The Personal Information collected from You is used in the following manner:',
                subItems: [
                  {
                    text: "For booking purposes: We use Your Personal Information, which may include Your and Your fellow travellers' name, email ID, payment details etc., to ensure that all bookings made on Headout Platform are concluded and enjoyed in satisfactory manner.",
                  },
                  {
                    text: 'For marketing purposes: We do run marketing and promotional campaigns along with the Service Providers and/or any other third parties and We may use Personal Information, which may include name, email ID, contact details, etc., to ensure that requisite benefits are passed on to Users under such marketing and promotional campaigns. Additionally, Users may also receive emails/notifications regarding marketing campaigns, newsletters, reward programs, and exclusive promotions offering special deals. Under this section, we shall also use the content submitted by the Content Creator for running promotional activities for Headout.',
                  },
                  {
                    text: 'Customer service: Headout also uses the information provided by the Service Providers (which may include personal data) to provide all sorts of support services. This may include but is not limited to, responding to requests, questions, or concerns from Service Providers or Users.',
                  },
                  {
                    text: "Registration and administrative purposes: Headout also uses contact details, and financial data to effectively manage the commercial arrangement with the Service Provider. Any information, including a Service Provider's name and address, will be used in accordance with this Policy and the agreement entered into with the Service Provider.",
                  },
                  {
                    text: 'For other reasons: We may also use Your Personal Information for several other reasons that include but are not limited to –',
                    bulletPoints: [
                      'Provide, maintain, and improvise Our services;',
                      'Communicate with You for different purposes including but not limited to keeping You informed about any updates with regards to the services availed on the Headout Platform;',
                      'Any changes made on the Headout Platform;',
                      'For customer service purposes;',
                      'For seeking reviews of services;',
                      'For survey purposes;',
                      'For banking purposes;',
                      'For any other purpose to provide You with best of the services.',
                    ],
                  },
                ],
              },
              {
                text: 'We share Your information with various third parties, including parties in other countries outside the United States of America, for purposes as mentioned hereinbelow:',
                subItems: [
                  {
                    text: "With Service Providers: We may share Users' Personal Information with Service Providers to offer Users certain products, services, or promotions.",
                  },
                  {
                    text: 'With Third Party Vendors: We may share Your Personal Information with Third Party Vendors to monitor and analyze the use of Our Service, contact You or initiate such appropriate action required to provide you Our services.',
                  },
                  {
                    text: 'With affiliates: We may share Your Personal Information with Our affiliates, in which case We will require those affiliates to honour this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners, or other companies that We control or that are under common control with Us. For reference, the list of our subsidiaries with which We may share Your Personal Information as a part of operations are:',
                    bulletPoints: [
                      'Tourlandish Online Ventures Private Limited',
                      'Headout Europe GmbH',
                      'Headout Events Tickets LLC',
                      'Headout UK Limited',
                    ],
                  },
                  {
                    text: 'With payment partners: for concluding the services provided through Headout Platform You will be required to make a payment or share your financial data with us like bank account/card details. We will process the relevant Personal Information required in each case depending on the services availed on Headout Platform. Similarly, some of Your Personal Information will be processed by parties who assist in the processing of the payment including but not limited to payment gateways, banking partners, or any other third party that may be engaged in such process.',
                  },
                  {
                    text: 'With a corporate: We may share or transfer Your Personal Information with a corporate in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.',
                  },
                  {
                    text: "With other users: when User shares Personal Information or otherwise interacts in public with other Users on the Platform or any other third-party/social media platforms, such information may be viewed by all users and may be publicly distributed outside. If User interacts with other Users or registers through third-party/social media platforms, User's contacts on the third-party/social media platforms may see User's name, profile, pictures, and description of its activity.",
                  },
                  {
                    text: 'Apart from the scenarios enumerated hereinabove, Your Personal Information may further be disclosed, if required:',
                    bulletPoints: [
                      'By law, by court order, by any enforcement authority or in reference to any legal purposes;',
                      'For audit and compliance purposes;',
                      'For any other purpose that is in the larger interest of You as a User.',
                    ],
                  },
                ],
              },
              {
                text: 'Some of the third parties/vendors may choose to retain some of Your Personal Information. However, that is for limited purposes to provide You with services in compliance with this Policy or any other terms that may be applicable to You by way of separate arrangements arrived with You by Headout. Please note that these third parties/vendors have informed us that they are compliant with respective privacy laws and have a detailed privacy policy in place. If You want to know about these third parties/vendors you can raise a request by writing on privacy@headout.com and we will try to address such requests in best possible manner.',
              },
            ],
          },
        ],
      },
      {
        title: 'User generated content and social media platforms:',
        content: [
          {
            type: 'list',
            items: [
              {
                text: 'Any content that is uploaded, submitted and/or posted by Users including but not limited to reviews, ratings, chats or discussions on the Platform or any social media platform is collectively referred to as, "User Content". User shall be solely responsible for the authenticity and correctness of all the User Content.',
              },
              {
                text: 'User Content posted by any User is visible to the other Users accessing the Platform or the social media platform where such User Content is posted. User agrees to have no objection regarding the same.',
              },
              {
                text: 'We may allow Users to create an account on the Platform using their personal social media accounts such as Facebook, Google etc. In such a scenario, We may capture some of Your Personal Information from Your social media account like Your first name, last name, email address, phone number, etc.',
              },
              {
                text: 'Further, when You interact with us on social media or through Our posts, We may collect and process the information that You may provide us during such interactions. This may happen when You "Like," "Share," or "Retweet" a post, leave a comment, or submit other content on Our social media platform. The processing of data in this regard is done in compliance with relevant privacy laws and to provide You with the best of services. Please also note that upon such usage of social media platforms, the privacy policies of those social media platforms and the privacy settings You have made with those social media platforms will also apply.',
              },
            ],
          },
        ],
      },
      {
        title: 'Data collection from Children',
        content: [
          {
            type: 'list',
            items: [
              {
                text: 'As per Our Terms of Use, We suggest that only a person who has attained the age of majority shall make the booking on the Platform. However, there could be certain scenarios where a booking will be made for children by a person who has attained the age of majority. For such scenarios, We would like to state that at Headout We value the privacy of everyone including children. If We receive the Personal Information of a child/ minor, We ensure that such information is processed lawfully and to the extent that consent is received for processing such information from a person of majority. In the event Headout becomes aware that the User is a minor or below the legal age to consent in the jurisdiction concerned, Headout reserves its right to terminate all services to such User/ Account without any prior notice.',
              },
            ],
          },
        ],
      },
      {
        title: 'For how long do We keep Your Personal Information?',
        content: [
          {
            type: 'list',
            items: [
              {
                text: 'Your Personal Information is retained on Our servers for such a period as may be required to meet the purpose for which such information was collected. However, We may retain Your Personal Information for a longer period, if in case required by law. Here is data retention schedule that further explains the retention period for each category of information collected from You:',
                tableData: {
                  headers: ['Information', 'Retention Period'],
                  rows: [
                    [
                      'Booking data, check-in data, special requests data',
                      'For as long as the User remains a customer',
                    ],
                    [
                      'Contact information of User',
                      'For as long as the User remains a customer',
                    ],
                    [
                      'Payment information',
                      'For as long as it is necessary to process the transaction/booking, including any queries which may arise.',
                    ],
                    [
                      'System logs',
                      '10 years or such longer period as may be required',
                    ],
                    [
                      'Information pertaining to Supply Partners or any other third party that commercially dealt with Us',
                      'Expiry of contract or for a period of 7 years, whichever is later.',
                    ],
                  ],
                },
              },
              {
                text: 'The Retention Period may vary, in case You exercise your rights provided under clause 12 hereinbelow.',
              },
              {
                text: 'In case if You have any doubt or if You feel there is a category of information not covered in the retention schedule above, please feel to write to our DPO at dpo@headout.com.',
              },
              {
                text: 'Where Your personal data is no longer required We will ensure it is either securely deleted or stored in a format in which it will no longer be used by the business.',
              },
            ],
          },
        ],
      },
      {
        title: 'How do We protect Your Personal Information?',
        content: [
          {
            type: 'list',
            items: [
              {
                text: 'We have put in place reasonable security measures to keep Your Personal Information guarded against any form of unauthorized access. Under this process of keeping Your Personal Information guarded, We have deployed security protocols as well as technical and physical limitations on access. Please be assured that Your Personal Information can only be accessed by authorized personnel who are permitted to access Personal Information in the course of their work.',
              },
            ],
          },
        ],
      },
      {
        title: 'Where do We store Your Personal Information?',
        content: [
          {
            type: 'list',
            items: [
              {
                text: 'Your Personal Information is stored in personnel files or within the electronic records (on servers in the USA or other countries) of Headout.',
              },
            ],
          },
        ],
      },
      {
        title: 'Your rights towards Your Personal Information',
        content: [
          {
            type: 'list',
            items: [
              {
                text: 'If You have a data privacy request, such as accessing or deleting your data, please reach out to us at privacy@headout.com. We will address Your request and respond within the applicable legal timeframe. You can also request a copy of the information we hold about You by emailing the same address. For any complaints regarding the processing of Your Personal Information, feel free to contact Us at the same email id. If You have any inquiries or concerns that require the attention of Our Data Protection Officer (DPO), kindly direct Your request to dpo@headout.com, and Our DPO will assist You accordingly. When handling any of these requests described above, We have the right to check the identity of the requester to ensure that he/she is the person entitled to make the request. In case of any complaint/disputes, You have the right to contact the supervisory authority of Your choice.',
              },
              {
                text: 'Additionally, as per law, You have the below mentioned rights available with You. To exercise these rights, You may raise a request by writing us at privacy@headout.com:',
                subItems: [
                  {
                    text: 'Right to Access Information\nYou have the right to request, at any time, a complete summary of the Personal Information we hold about You.',
                  },
                  {
                    text: 'Right to Correct Inaccurate Data\nIf You believe that any Personal Information We maintain about You is incorrect or incomplete, You may request its prompt correction.',
                  },
                  {
                    text: 'Right to Erasure\nYou are entitled to ask Us to delete your personal data when it is no longer necessary for its original purpose, has been unlawfully processed, or where an applicable legal requirement for erasure exists.',
                  },
                  {
                    text: 'Right to Restrict Processing\nYou may request that We limit how We process your personal data under the conditions specified under relevant law. This right can be invoked, for example, if You contest the accuracy of Your data or if You need it preserved solely for the purpose of establishing, exercising, or defending legal claims.',
                  },
                  {
                    text: 'Right to Data Portability\nYou have the right to receive the Personal Information You have provided to Us in a structured, commonly used, machine-readable format, enabling You to transfer this data to another service provider.',
                  },
                  {
                    text: 'Right to Object\nYou may object, at any time and on grounds specific to Your situation, to the processing of Your personal data. In such cases, We will halt the processing of Your data unless We can demonstrate compelling legitimate reasons for its continued use or that it is necessary for the exercise or defense of legal claims.',
                  },
                  {
                    text: 'Right to Withdraw Consent\nYou have the right to revoke any consent you have previously given for the processing of your Personal Information at any time.',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'Interpretation of Privacy Policy and Changes in it',
        content: [
          {
            type: 'list',
            items: [
              {
                text: 'Since this Privacy Policy is commonly applicable on all of our Users, Supply Partners, Affiliate Partners, Creator Partners etc., it shall always be interpreted with either the [Terms of Use], [Supply Partner Agreement], [Affiliate Partner Terms] and [Creator Partner Terms] for better understanding and interpretation. In the event of any discrepancy, inconsistency, or conflict between the English version of this Policy and any translation, the English version shall prevail and govern the interpretation and enforcement.',
              },
              {
                text: 'We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page. We will also put a "Last updated" date tag at the top of this Policy, whenever it stands updated. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.',
              },
            ],
          },
        ],
      },
    ],
  },
  TERMS_OF_USE_CONTENT: {
    title: 'Headout: Terms of Use',
    introduction:
      'These Terms of Use will be effective from the Last Updated date mentioned above ("Terms of Use").',
    lastUpdated: '31st March 2025',
    sections: [
      {
        title: 'Definitions',
        items: [
          'Additional Information: Headout may seek certain information about the User and all other persons who are meant to be part of the Booking, to assess eligibility to complete the Booking and/or obtain the relevant Services.',
          'Book/ Booking: this means booking a Service by the User on the Headout Platform/ Website.',
          'Booking Confirmation: this includes a booking confirmation voucher/ticket along with a confirmation email issued by Headout once the User completes payment against a Booking.',
          'Experiences/ Services: it includes sightseeing tours, leisure activities, attraction tickets and other tourism and entertainment services.',
          'Headout/ We: Headout Inc. is a Delaware corporation, having its address at 82 Nassau St #60351 New York, NY 10038 and includes its affiliates, group/ related companies, its successors and assigns.',
          "Headout Account: the account created by any User while registering on Headout's Platform/ Website.",
          'Headout Account Credentials: means the login ID and the password set by any User while creating a Headout Account.',
          'Headout Credits: are booking credits valid for future bookings made on the Platform. These can be acquired by User(s) as a refund against a cancelled booking or service, through targeted marketing campaigns or other mechanisms as Headout may determine at its discretion. Headout Credits are only available to Users who have a valid Headout Account.',
          'Interactive Forums: means discussion forums, bulletin boards, review services or other forums in which You and other users of the Website may post User Content.',
          'Listing: each Experience or Service listed on the Platform.',
          'Platform/ Website: The Internet booking platform of Headout accessible via www.headout.com. It also includes related websites, affiliate and partner websites, mobile applications, tools, platforms, linked social media accounts or other facilities.',
          'Privacy Policy: a legal document that governs the privacy terms of Headout.',
          'Refund Amount: means the amount processed/ refunded by a Supplier on cancellation of Booking.',
          "Sales Channel: it includes Headout's websites, mobile applications, salespersons, call centres, branch offices, agents etc.",
          'Suppliers: it includes the end service provider i.e. tour operators, activity organizers, attractions, travel agents and ticket suppliers.',
          'Supplier Terms: the terms and conditions specified by the Supplier that will govern the provision of the Services provided by the Supplier and availing of the Services.',
          'Terms of Use: a legal document that governs the usage of any Sales Channel by the User.',
          'Third Party Websites: means websites operated by parties other than Headout.',
          "User/ You/ Your: any person who visits/ shows interest/avails any Service available on Headout's Platform or through any of its Sales Channel.",
          "User Content: means any content posted by a User on the Platform in several different ways, including but not limited to writing reviews, making posts, uploading and posting photos or videos, making comments or suggestions, submitting ideas, rating other users' contributions and making other similar contributions or submissions to the Website and/or otherwise to Headout. The User Content is visible to all Users of the Platform.",
          'Website Content: means the content and information available on the Website including, but not limited to, messages, data, information, text, music, sound, photos, graphics, video, maps, icons, software, code or other material.',
        ],
      },
      {
        title: 'Introduction',
        items: [
          'These Terms of Use together with the Privacy Policy, constitute a binding contract between the User and Headout. These Terms of Use govern inter alia usage of any Sales Channel by the User including but not limited to Platform/ Website. Headout operates the Platform/ Website that allows inter alia (a) Suppliers to offer Experiences/ Services listed on the Platform to the User(s); and (b) the conclusion of contracts directly between User(s) and the Suppliers of such Services.',
          'Headout is not a travel or tour agency and does not provide such Experiences or Services on its own. When a User books an Experience or Service on the Platform, it purchases the same directly from the Supplier. Headout through its Platform only facilitates the purchase of tickets to the Experiences. Headout also does not act as an agent to the User, in any capacity, except to the extent of facilitating payments by User to the Suppliers.',
        ],
      },
      {
        title: 'Access and Use of the Platform',
        items: [
          'By accessing, registering and/or by making a Booking on the Platform, User agrees to each of the terms mentioned in the Terms of Use, without any modification. The User represents that he/she has read and understood the Terms of Use. In all Bookings, the person making the Booking shall be deemed to have accepted these Terms of Use on behalf of all persons named in the Booking.',
          "Headout may revise these Terms of Use from time to time. The revised version will be effective from the date it gets posted unless otherwise specified. If changes/revisions to these Terms of Use reduce Your rights or increase Your responsibilities, Headout will post a notice of the same on the Headout website or intimate its Users via email. By continuing to use the Platform after Headout carries out changes to these Terms of Use, You agree to abide by and be bound by those changes. If You are not agreeable to any of the terms mentioned in this document, You must not use or access Headout's Platform.",
          'As a condition to the use of the Platform, You represent and warrant that: (i) all information supplied by You in the course of the use of the Platform and/or a completing Booking is true, accurate, current and complete, and (ii) You are 18 years or older.',
          'If You as a minor wants to make any Booking on the Platform, You are bound to ensure that such Booking shall only be made by a person that has attained the age of majority (parents, siblings or legal guardian).',
          'Your use of the Website and carrying out Bookings is permitted only for personal, non-commercial use and/or to make legitimate requests to make a Booking of the Experiences offered on the Website. You agree not to use the Website to make any speculative, false or fraudulent requests for Bookings. In case, if Headout becomes aware of any such instance, Headout will have the right to cancel such bookings and the User will have no right to claim any damages from Headout.',
          'You further represent and warrant that (a) Your Headout Account is not currently suspended and you have not previously been restricted by Headout from using the Website; (b) You are not acting on behalf of a competitor of Headout; (c) You will not create more than one Headout Account; and (d) You have full power and authority to enter into this legally binding agreement with Headout and in doing so will not violate any other agreement to which You are a party.',
          'We retain the right at our sole discretion to deny access to the Website to anyone at any time and for any reason, including, but not limited to, for violation of these Terms of Use.',
          'By accessing and using the Website, You agree and acknowledge that Headout does not endorse any of the Listings or content on the Website and Headout cannot vouch for its accuracy. You also agree that Headout merely provides a marketplace platform and is not an actual supplier of any Experience. You, therefore, access and use the Website at Your own risk.',
          'You may need to create a Headout Account and provide information about Yourself to use some of the features on the Website, including when You make a Booking. You are responsible for maintaining the confidentiality of Your Headout Account Credentials. You are also solely responsible for all activities (including Bookings) that occur in connection with Your Headout Account. You agree to notify us immediately of any unauthorized use of Your Headout Account by sending an email to support@headout.com. You also agree that in case of any unauthorized use of Your Headout Account, You will not raise any claim against Headout. As a User of the Website, you understand and agree that neither Headout nor its affiliates will have any liability to you or others for any unauthorized bookings made using your Headout account and/or Headout account credentials. In case you notice any unauthorized use of your Headout Account, you will immediately (1) change the Headout Account Credentials and (2) inform Headout about such unauthorized use.',
          'We may terminate or suspend access to Your Headout Account or Your ability to use the Website, in whole or in part, at our sole discretion, for any or no reason, and without notice or liability of any kind. For example, We may terminate or suspend Your Headout Account or ability to use the Website if You misuse the Website. Any such termination or suspension could prevent You from accessing Your Headout Account, the Website, User Content, Website Content, and/or any other related information including but not limited to your active Bookings.',
          'You may suspend Your Headout Account at any time by contacting us to request that We disable Your Headout Account, and by discontinuing Your use of any and all parts of the Website. If You suspend Your Headout Account, We may continue to display Your previously published User Content and are under no obligation to remove any of such User Content.',
        ],
      },
      {
        title: 'Searching and Booking on Headout',
        items: [
          'You can search for Experiences by using the various options made available on the Website or by using criteria like the type of Experience, travel destination etc. Search results are based on their relevance to Your search and other criteria.',
          'When You Book a Listing, You are agreeing to pay the price mentioned in the Listing, applicable taxes and other applicable fees like the Headout service fee and any other fees/charges identified during check-out. The price of each Listing will be quoted on a per-person basis unless otherwise specified. The prices are based on the tariff specified by the Supplier who has made the concerned Listing available on the Platform. Headout does not determine the pricing of the Listings. The prices will be listed in the currency chosen by You while using the Website. Any currency conversions required to effect payment for the Booking will be done at the rate determined by Headout. The prices quoted against a Listing are subject to change without notice until a Booking has been made.',
          'Services included in a Booking are determined by the Supplier. Prices quoted in a Listing only include the services as specifically shown on the listing page.',
          'While these Terms of Use govern Your use of the Website, including Your request for a Booking, the provision of the Services by the Supplier and Your availing of the Services will be governed by the Supplier Terms. These Suppliers Terms may be specified in the Listing, communicated to You following the completion of the Booking, or be available on the website of the Supplier or the concerned Experience. You agree to read and understand and be bound by the Supplier Terms before availing of the Services under a Booking. Notwithstanding the foregoing, to the extent, the Supplier Terms conflict with these Terms of Use in respect of Your legal relationship with Headout, these Terms of Use shall prevail. Your interactions with Suppliers are at Your own risk.',
          "You further agree and acknowledge that Headout shall not be liable to You or any other person for any losses, expenses, damages (including property damage) or injuries caused to You on account of (i) the Supplier Terms not being communicated to You; (ii) Your failure to read and understand the Supplier Terms; (iii) the Supplier's breach of the Supplier Terms; (iv) any error, defect or inaccuracy in the Supplier Terms; or (v) any other acts, omissions, errors, representations, warranties, breaches or negligence of a Supplier.",
          "The Booking of certain Listings requires You to meet certain prerequisites or criteria to establish Your eligibility to Book the Listing and avail of the relevant Services. You are required to read and understand the prerequisites or criteria which are part of the Supplier Terms. We may even seek certain Additional Information, to assess User's eligibility to complete the Booking and/or obtain the relevant Services. You agree to provide the Additional Information accurately. If a Booking is completed based on inaccurate Additional Information and the Supplier finds You ineligible to obtain the Services, then Headout will not be liable for any losses, damages or costs incurred by You in connection with such Booking. Headout will also not be under an obligation to refund the Booking price if You are unable to obtain the Services from the Supplier because the Supplier found You ineligible for the same.",
          'When You complete payment against a Booking a Booking Confirmation will be issued from Headout. On completion of payment against a Booking, a bipartite contract for the supply and purchase of the Services is formed directly between You and the Supplier.',
          "In addition to these Terms of Use, a User shall also read the FAQs available in the 'Help' section on Headout's Website to have a detailed understanding of the services provided by Headout. The user agrees that it shall be bound by the content of such FAQs in addition to these Terms of Use.",
        ],
      },
      {
        title: 'Payments',
        items: [
          'When You make a Booking, Headout collects Your payment information and processes the payment to ensure that the price paid by You for the Booking is transferred and deposited to the account of the Supplier, after the deduction of Headout charges, if any. In doing so, Headout has a limited responsibility of collecting the payment from the User and transferring it to the Supplier. Full payment is required to make or confirm a Booking unless otherwise specified.',
          'The value of the Booking may be subject to duties, foreign transactions fee, currency exchange or other fees applicable in Your country of residence. Your bank or payment card company may convert the payment into the local currency (of Your country of residence) and may charge fees, resulting in differences between the amount displayed on the Listing, and the final amount charged to You. Headout recommends that You contact Your bank or card company if You have any questions concerning any applicable currency conversion or fees.',
          'Headout Credits can be used by registered Users of Headout for future bookings. Headout Credits are non-transferable and cannot be refunded or redeemed for cash. Headout reserves the right to make changes to the usage terms of Headout Credits, including but not limited to their value, usage and validity without prior notice or intimation. In case, if Headout finds out that You are misusing the Headout Credits intending to cause an illegitimate loss to Headout and gain to Yourself, Headout shall have all the rights to take appropriate action including but not limited to reversal of Headout Credits and deactivation of Headout Account.',
          "Headout being an online marketplace cannot issue a tax invoice for the Booking made by a User. However, upon the User's written request, Headout may issue a payment receipt for a completed booking. Such receipt shall include details including, but not limited to, the amount paid, any Headout Credits applied, the guest's name, and the relevant tour details. The User may request a receipt by contacting support@headout.com, and Headout shall endeavor to issue the same within twenty-four (24) hours of receiving the request. Headout reserves the right to determine the format and content of the receipt at its sole discretion.",
        ],
      },
      {
        title: 'Cancellations and Booking Modifications',
        items: [
          'Once a Booking is completed, it cannot be changed by You or cancelled with a refund, unless otherwise stated in the Listing or the Supplier Terms applicable to such Listing.',
          'All matters concerning cancellation and modifications of Bookings are decided and/or determined by the Supplier. Given that the purchase of the Experience is a contract between You and the Supplier, Headout does not frame the terms of cancellation/modification of the Bookings or take any decision regarding cancellation/modification of the Bookings, unless agreed to the contrary between Headout and the concerned Supplier.',
          'Subject to the terms of cancellation/modification set out in the Listing or Supplier Terms, You may: (i) opt to either cancel or modify a Booking; or (ii) reach out to Headout at the contact details specified in the Booking Confirmation to inquire about the options available for cancellation/modification of a Booking. Headout may, on receiving such a request, if required, contact the concerned Supplier and obtain information or seek confirmation before cancellation/modification of a Booking.',
          'If a Booking has been cancelled/modified by You as per the terms mentioned herein and as per the cancellation terms of the Supplier, Headout will, subject to receipt of the Refund Amount from the Supplier, refund the applicable amount to You. Headout clarifies that under no circumstances will Headout pay You the Refund Amount until Headout receives it from the Supplier. Once Headout receives the Refund Amount from the Supplier, Headout will endeavour to initiate the refund within seven (7) days of receipt from the Supplier. As a default practice, Headout processes the Refund Amount to the original source of payment. However, In case the original source of payment is unavailable, You can choose to receive the refund in the form of Headout Credits to the Headout Account of the user. These Headout Credits are neither transferable nor can be refunded or redeemed in the form of cash. As referred previously, currently, Headout Credits cannot be used partially for a booking. Headout reserves the right to make changes to the usage terms of Headout Credits, including but not limited to, value, usage, and validity without prior notice or intimation.',
          'If You make a Booking using a promotion code, or if You accept a discount or special-offer price during the check-out process, You may invalidate Your discount or special-offer price by cancelling/modifying Your Booking. Headout will process any refund to You after adjusting such discount or special offer availed by you at the time of Booking.',
          "No refunds are available once an Experience has commenced, or in respect of any Experience's package, accommodation, meals or any other Services that You have started to utilize.",
          'A Supplier may make a change to a Booking after Your purchase, changes including but not limited to the date, price, inclusions, coverage, age requirements, and/or any other features and/or requirements of the Experience. Headout will endeavour to communicate to You any such changes at the earliest possible opportunity, as soon as Headout becomes aware of the same. Your rights, concerning such changes, vis-à-vis the Supplier, shall be governed by the Supplier Terms. Headout shall not be liable to You or any other person for any losses, expenses, costs, damages or injuries arising on account of or about such changes made to the booking.',
          "Pursuant to such change(s), if the Supplier cancels the Booking or allows You to cancel the Booking, then Headout will endeavour to process the Refund Amount received from the Supplier within seven (7) days of receipt of the same from the Supplier. If the Supplier permits You to modify the Booking pursuant to the changes, any information that We receive on this matter will be communicated to You as soon as We become aware. Headout will not be liable to You or any other person for any losses, expenses, costs or damages arising on account of or concerning (i) Your inability to modify the Booking, (ii) the Supplier's failure to communicate regarding the modification of the Booking with either us or You, or (iii) the Services availed pursuant to modification of the Booking.",
          "We may decide, in our sole discretion, that it is necessary or desirable for the protection of our interests, the Supplier's interests and/or Your interests, to withdraw our services resulting in an override of the Supplier's cancellation policy. We may also determine, in our sole discretion, to arrange for You a refund of a part or all of the amounts charged to You. You agree that We will have no liability for such cancellations or refunds.",
          'In any scenario of cancellation of any Booking, You agree and understand that Headout shall be only liable to process the refund of the Booking amount upon receiving the same from the Supplier. You also agree that all liabilities against any Booking are attributable to the Supplier. Headout will not undertake any other liability of any sort including but not limited to any incidental losses incurred by You.',
        ],
      },
      {
        title: 'Your Responsibility and Assumption of Risk',
        items: [
          'You are responsible and liable for Your acts and omissions and are also responsible for the acts and omissions of anyone participating in the Experience. For example, this means: (i) You are responsible for leaving the venue of an Experience in the same or substantially the same condition it was in when You arrived; (ii) You must act with integrity, treat others at the venue of the Experience with respect, always comply with all applicable laws and any terms or rules as may be set out by the Supplier. Your failure to comply with the applicable local laws or terms set out by the Supplier may result in the Supplier asking you to leave/exit the Experience or attraction or any other action as per law.',
          'You acknowledge that the activities following the Booking carry inherent risks and accordingly agree that, to the maximum extent permitted by applicable law, You assume the entire risk arising out of Your access to and use of the Platform, participation in any Experience, any other interaction You have with other persons at the venue of the Experience or online. This means You are responsible to analyze and understand that the Experience chosen by you for the Booking is suitable for You by all means and manners. For example, Experiences may carry the risk of illness, bodily injury, disability, or death, and You freely and willfully assume those risks by choosing to participate in those Experiences.',
        ],
      },
      {
        title: 'Website Prohibited Activities',
        items: [
          'The Website Content, as well as the infrastructure used to provide such Website Content, is either proprietary to us or granted to us under a valid license. You agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any Website Content, or any products or services obtained from or through the Website. Any other use of the Website Content, products and/or services requires the prior written permission of Headout.',
          {
            text: 'Additionally, You agree that You will not and will not assist or enable others to:',
            subItems: [
              'use the Website or the Website Content for any commercial or unlawful purpose',
              'access, monitor or copy any Website Content using any robot; spider, scraper or other automated means or any manual process to access, scrape, index, retrieve or otherwise use the Website or any Website Content for any purpose without our express written permission',
              'violate the restrictions on the Website or bypass or circumvent other measures employed to prevent or limit access to the Website',
              'take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure or makes excessive traffic demands on the Website',
              '"frame", "mirror" or otherwise incorporate any part of the Website into any other website without our prior written authorization',
              'attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software associated with the Website',
              "use the Website to threaten, stalk, defraud, incite, harass, or advocate the harassment of another person, or otherwise interfere with another user's use of the Website",
              'use the Website to promote bigotry or discrimination against protected classes',
              'use the Website to violate any third-party right, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right',
              'use the Website to submit or transmit pornography or illegal content',
              'use the Website to solicit personal information from minors, or to harm or threaten to cause harm to, any person including minors',
              'attempt to gain unauthorized access to the Website, user accounts, computer systems or networks connected to the Website through hacking, password mining or any other means',
              'use the Website to transmit any computer viruses, worms, defects, Trojan horses or other items of a destructive nature',
              'use any device, software or routine that interferes with the proper working of the Website, or otherwise attempts to interfere with the proper working of the Website',
              'use the Website to violate the security of any computer network, crack passwords or security encryption codes',
              'disrupt or interfere with the security of, or otherwise cause harm to, the Website',
              'remove, circumvent, disable, damage or otherwise interfere with any security-related features of the Website, features that prevent or restrict the use or copying of Website Content, or features that enforce limitations on the use of the Website',
              'post any content on the website that violates of or is specifically prohibited under the laws of the United States of America or the local laws of the country from where the Website is being accessed.',
            ],
          },
        ],
      },
      {
        title: 'Fraudulent Activities',
        items: [
          'We maintain a zero-tolerance policy for any fraudulent activity. On certain occasions, where We suspect that occurance of a fraudulent transaction, We may contact You to request additional information to verify the legitimacy of Your booking and ensure it is not associated with any fraudulent activities. If You choose not to provide this requested information, We reserve the right to cancel Your booking. Please note that these actions are strictly in best interest of all the Users as well as the Suppliers and Headout will not be responsible for any such cancellations.',
          'Fraudsters may employ different phishing techniques to obtain personal and confidential information including payment details of indivduals, with an ill intent to defraud them. These phishing techniques may involve the creation of fake websites, messages, emails, and more. We strongly advise You to remain vigilant and informed about these phishing methods. If You come across or become a victim of any such phishing attempts in the name of Headout, we kindly request that You promptly report such activities to orm@headout.com. We also urge You to immediately report such transactions to Your bank or any such other authority as may be required under law.',
        ],
      },
      {
        title: 'Electronic Communications',
        items: [
          'When You use the Headout Website, or send e-mails, text messages, and other communications from Your desktop or mobile device to us, You are communicating with us electronically and agree that We may communicate with You in a variety of ways, such as by e-mail, in-app push notices, or by posting notices and messages on the Website.',
          'You may unsubscribe from marketing-related e-mails by raising a request on support@headout.com. Any such request will be addressed within seven (7) working days of receipt of the request. However, Headout may continue to send You non-marketing messages, such as messages related to any upcoming Bookings.',
          'You will keep Headout indemnified against any/ all types of losses, expenses, costs, damages or injuries arising on account of or concerning any action taken by any local telecom-related regulatory authority or any other authority due to any erroneous complaint raised by the User with respect to the communications mentioned hereinabove or due to a piece of wrong contact information provided by the User for any reason whatsoever.',
        ],
      },
      {
        title: 'Content',
        items: [
          'You and other users of the Website may post or submit some User Content to Headout or on its Website. We may use such User Content in several different ways, including but not limited to displaying it on the Website, reformatting it, translating it into other languages, editing it for clarity and grammar, incorporating it into advertisements and other works, creating derivative works from it, promoting it,  and distributing it. You also agree that Headout may reach out to You to post reviews and/or shoot videos or images of the experiences booked by You which is entirely optional. In case, You agree to share such User Content with Headout, Headout shall have the unlimited right to use, repurpose or reproduce such User Content on its Platform. No consideration shall be payable by Headout to You for such User Content.',
          'By submitting User Content, You grant Headout and its subsidiaries and/ or affiliates a worldwide, non-exclusive, royalty-free, fully paid-up, perpetual, transferable, irrevocable and fully sub-licensable right to (a) use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and publicly display and perform such User Content throughout the world in any media, now known or hereafter devised, for any purpose; and (b) use the name that You submit in connection with such User Content. You acknowledge that Headout may choose to provide attribution of Your User Content at our discretion. You acknowledge and agree that User Content is non-confidential and non-proprietary. If it is determined that You retain moral rights (including rights of attribution or integrity) in the User Content, You hereby declare that (a) You do not require that any personally identifying information be used in connection with the User Content, or any derivative works of or upgrades or updates thereto; (b) You have no objection to the publication, use, modification, deletion and exploitation of the User Content by Headout or its licensees, successors and assigns; (c) You forever waive and agree not to claim or assert any entitlement to any and all moral rights of an author in any of the User Content; and (d) You forever release Headout, and its licensees, successors and assigns, from any claims that You could otherwise assert against Headout under any such moral rights.',
          'The Website also contains some Interactive Forums and You are solely responsible for Your use of such Interactive Forums. You agree that You will use such Interactive Forums at Your own risk. By using any such  Interactive Forums, You expressly agree not to post, upload, transmit, distribute, store, create or otherwise publish through the Website any User Content that comprises or includes:',
          {
            text: 'Prohibited content includes:',
            subItems: [
              'content that is unlawful, libellous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, fraudulent or otherwise objectionable',
              'content that would constitute, encourage or provide instructions for a criminal offence, violate the rights of any party, or would otherwise create liability or violate any applicable law',
              'content that may infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary rights of any party',
              'content that impersonates any person or entity or otherwise misrepresents Your affiliation with a person or entity, including Headout',
              'private information of any third party, including, without limitation, name, address, telephone number, e-mail address and credit card number',
              'Viruses, corrupted data or other harmful, disruptive or destructive files',
              'content that is unrelated to the topic of the Interactive Area(s) in which such User Content is posted',
              'content or links to content that, in the sole judgment of Headout (i) violates the previous sub-sections herein, (ii) is objectionable, (iii) restricts or inhibits any other person from using or enjoying the Interactive Areas or the Website, and/or (iv) may expose Headout or its affiliates or Website users to any harm or liability of any type',
            ],
          },
          "You alone are responsible for the User Content You submit. You assume all risks associated with such User Content, including anyone's reliance on its accuracy, completeness or usefulness, or any disclosure by You of information in such User Content that makes You personally identifiable. You represent that You own, or have the necessary permissions to use, and authorize the use of, such User Content as described herein. You may not imply that such User Content is in any way sponsored or endorsed by Headout.",
          'User shall be solely responsible and liable for any User Content posted, stored or uploaded on the Platform, or for any loss or damage thereto. User agrees to not post any User Content that may amount to defamation, slander, libel, omissions, falsehoods, obscenity, pornography, profanity or is in breach of law in any manner. In case Headout is held liable by any court of law or any governmental authority for any User Content posted by You on the Website, You agree to hold Headout indemnified against any such losses and/ or damages suffered by Headout.',
          'Headout may screen, edit or monitor User Content. Headout reserves the right, and has absolute discretion, to remove, screen, translate or edit without notice any User Content at any time and for any reason, and/or to have such actions performed by third parties on its behalf, and You are solely responsible for creating back-up copies and replacements of Your User Content at Your sole cost and expense. Headout has no obligation to retain or provide You with copies of Your User Content.',
          'If any User identifies Content on the Headout platform that is unlawful or otherwise in contravention of applicable laws, they may submit a written complaint to legal@headout.com. Upon receipt of such a complaint, Headout shall use its best efforts to review and address the issue within 3 business  days. Headout will notify the complainant of its decision, within the same timeframe. Headout reserves the right to take any action it deems appropriate, including but not limited to the removal or modification of the reported content.',
          "If a User is adversely affected by a content moderation action taken by Headout, they may raise a dispute by submitting a written request to legal@headout.com within six (6) months from the date of such action. Headout shall review the request and communicate its decision to the User. If the User is dissatisfied with Headout's determination, they may pursue out-of-court dispute resolution mechanisms, as may be available under applicable law.",
          'Any use by You of the Interactive Forums or other portions of the Website in violation of the foregoing violates these Terms of Use and may result in, among other things, termination or suspension of Your rights to use the Interactive Forums, the Website and/or closure of Your Headout Account.',
        ],
      },
      {
        title: 'Links to Third Party Websites',
        items: [
          'The Website may contain hyperlinks to Third Party Websites. Such hyperlinks are provided for Your reference only. Their presence on the Website does not signify any endorsement by Headout of the material on such Third-Party Websites nor any association with their operators. We do not control Third Party Websites and are not responsible for their contents, or the privacy or other practices of those that own and operate them. It is Your sole responsibility to ensure that the hyperlinks You select and/or software You download from any such Third-Party Websites are free of viruses.',
          'In some cases, You may be asked on a Third-Party Website, whether You wish to link Your profile with the Platform Headout. Choosing to do so is completely optional. If You decide to link Your profiles and You later wish to disable such links, You should contact the relevant Third-Party Website.',
          "You may notice some Third Party Websites links available on the Headout's Website. Such links if accessed by the User shall be at their own risk and Headout shall not be held liable in case of any loss or damage suffered by accessing such links and sharing personal information on such links voluntarily. Users will be bound by the terms of usage and privacy policy of such Third Party Websites and are advised to review such terms.",
        ],
      },
      {
        title: 'Digital Millennium Copyright Act Compliance',
        items: [
          'If You believe that Your work has been copied on our Website, or any of our other systems or networks in a way that constitutes copyright infringement, please write to us at IP@headout.com. While sending any notice under this head of any alleged infringement, please provide the following information to Headout:',
          {
            text: 'Your notice must include:',
            subItems: [
              'An electronic or physical signature of the person authorised to act on behalf of the owner of the copyright interest',
              'A description of the copyrighted work that You claim has been infringed',
              'A description of where the material that You claim is infringing is located on the Website',
              'Your address, telephone number and email address',
              'A statement by You that You have good faith and belief that the alleged use is not authorised by the copyright owner, its agent or the law',
              "A statement by You, made under penalty of perjury, that the above information in Your notification is accurate and that You are the copyright owner or authorised to act on the copyright owner's behalf",
            ],
          },
          'Headout will only respond to those notices that substantially comply with the above requirements. We will investigate Your claim and will notify you by the method of contact You used to file Your notice with us.',
          'To ensure that Your notification complies with the requirement of the Digital Millennium Copyright Act, please see 17 U.S.C. § 512(c)(3).',
        ],
      },
      {
        title: 'Disclaimer of Warranties and Limitation of Liability',
        items: [
          'WE PROVIDE THE PLATFORM AND ALL WEBSITE CONTENT "AS IS" WITHOUT WARRANTY OF ANY KIND AND WE DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED. FOR EXAMPLE: (I) WE DO NOT ENDORSE OR WARRANT THE EXISTENCE, CONDUCT, PERFORMANCE, SAFETY, QUALITY, LEGALITY OR SUITABILITY OF ANY SUPPLIER, LISTING OR EXPERIENCE; (II) WE DO NOT WARRANT THE PERFORMANCE OR NON-INTERRUPTION OF THE WEBSITE; AND (III) WE DO NOT WARRANT THAT VERIFICATION, IDENTITY OR BACKGROUND CHECKS CONDUCTED ON LISTINGS OR SUPPLIERS (IF ANY) WILL IDENTIFY PAST MISCONDUCT OR PREVENT FUTURE MISCONDUCT. THE DISCLAIMERS IN THESE TERMS OF USE APPLY TO THE MAXIMUM EXTENT PERMITTED BY LAW. IF YOU HAVE STATUTORY RIGHTS OR WARRANTIES WE CANNOT DISCLAIM, THE DURATION OF ANY SUCH STATUTORILY REQUIRED RIGHTS OR WARRANTIES, WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.',
          'NOTWITHSTANDING ANYTHING CONTAINED HEREIN, IN NO EVENT WILL HEADOUT BE LIABLE TO YOU OR ANY OTHER PARTY FOR ANY CONSEQUENTIAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES, WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGE WAS FORESEEABLE AND WHETHER OR NOT HEADOUT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.',
          "HEADOUT'S AGGREGATE LIABILITY UNDER THESE TERMS OF USE SHALL NOT EXCEED THE AMOUNT PAID BY YOU TO HEADOUT IN CONNECTION WITH THE BOOKING WHICH FORMS THE SUBJECT MATTER OF A CLAIM HEREUNDER. THE DISCLAIMERS AND LIMITATIONS IN THIS CLAUSE WILL APPLY NOTWITHSTANDING ANY FAILURE OF THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.",
        ],
      },
      {
        title: 'Force Majeure',
        items: [
          'The User agrees that there can be certain exceptional circumstances where Headout and/or the Supplier may end up in a scenario where they will not be in a position to honour the confirmed bookings due to various reasons like an act of God, a pandemic, an epidemic, business exigencies, labour unrest, insolvency, government decisions, terrorist activity, any operational and technical issues, or any other reason beyond the control of Headout and/or Supplier.',
          'The User understands and agrees that Headout merely being a marketplace, where Booking has been made, cannot be held responsible and liable for any such Force Majeure circumstance leading to the cancellation of a Booking. However, in case Headout has any advance knowledge of any such scenario that can lead to cancellation or dishonour of bookings, it will have a limited responsibility to refund the booking amount after deducting requisite and applicable charges if refunded by the concerned Supplier.',
          "The User further agrees that in case the Booking doesn't get confirmed because of some technical reasons (like network downtime, the issue with a third party server, the issue with payment gateway/banks etc.) or any other similar issues, Headout will have limited responsibility of refunding the booking amount, if any, received from the User. The user also agrees that once the said refund gets processed, Headout will be discharged from all the liabilities.",
          'In case there are any indirect, punitive, incidental, special or consequential damages, Headout will not be liable for the same and the User will not come after Headout for recovery of any such losses.',
        ],
      },
      {
        title: 'Indemnification',
        items: [
          "You shall indemnify, defend, and hold Headout and its employees, directors, agents, and Suppliers harmless from and against any action, claim, suit, proceeding, demand, investigation, or charge alleging any costs, losses, liabilities, damages, fines, judgments, fees, or expenses (including reasonable attorneys' fees and court costs) arising out of (i) Your breach of these Terms of Use or the Supplier Terms (ii) Your failure to comply with applicable law in connection with the use of the Website or in obtaining the Services; or (iii) any personal injury, property damage, or death resulting from Your act or omission either in connection with the use of the Website, participation in the Experiences or availing of any of the Services.",
        ],
      },
      {
        title: 'General Provisions',
        items: [
          'Governing Law and Jurisdiction: These Terms of Use will be governed by and construed and enforced in accordance with the laws of the State of New York, without reference to conflict of laws principles. Each Party hereby irrevocably submits to the exclusive jurisdiction of the state and federal courts in the State of New York with regard to any dispute arising out of or relating to these Terms of Use.',
          'Injunctive Relief: You acknowledge and agree that a breach of some of Your obligations herein may cause substantial harm to Headout, which could not be remedied by payment of damages alone. Accordingly, You hereby agree that Headout will be entitled to seek preliminary and permanent injunctive relief, without a requirement to post a bond, in any jurisdiction where damage may occur, in addition to all other remedies available to it for any such breach.',
          "Assignment; Binding Effect: You shall not assign or delegate these Terms of Use or any of Your rights, or duties under these Terms of Use without the prior written consent of Headout, and any purported assignment shall be void and of no force or effect. Headout may freely assign or delegate these Terms of Use or any of its licenses, rights, or duties hereunder at Headout's sole discretion. These Terms of Use will be binding upon and inure to the benefit of Headout, its successors, and permitted assigns.",
          'Partial Invalidity: If any provision of these Terms of Use is held to be invalid or unenforceable by a court of competent jurisdiction, then the remaining provisions will, nevertheless, remain in full force and effect, and such provision will be reformed in a manner to effectuate the original intent of the provision in the form it was incorporated into these Terms of Use. If such reformation is not possible in a manner that is enforceable, then such term will be severed from the remaining terms, and the remaining terms will remain in effect.',
          "No waiver: No waiver of any term or condition of these Terms of Use will be valid or binding on Headout unless the same has been assented to in writing by an officer of Headout. Headout's failure to enforce at any time any of the provisions of these Terms of Use, or the failure to require at any time performance by You of any of the provisions of these Terms of Use, will in no way be construed to be a present or future waiver of such provisions, nor in any way affect Headout's ability to enforce each and every such provision thereafter.",
          'Entire Agreement: Between You and Headout, these Terms of Use set forth the entire agreement and understanding relating to the subject matter herein and supersede all prior and contemporaneous communications, representations, discussions, and agreements concerning such subject matter.',
          'In the event of any inconsistency or conflict between the English version of these Terms of Use and any translation, the English version shall prevail and be the governing document for all purposes.',
        ],
      },
    ],
  },
};

export default en;
