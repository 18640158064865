/*eslint sort-keys: ["error", "asc", {natural: true}]*/
import Experiment from 'utils/experiments/experiment';

export const VARIANTS = {
  CHECKOUT_REDIRECT: 'LP to booking page',
  CONTROL: 'Control',
  CONTROL1: 'Control 1',
  CONTROL2: 'Control 2',
  SHOWPAGE_REDIRECT: 'LP to showpage',
  TREATMENT: 'Treatment',
};

const LFC_IMPACT = 'LFC Impact';
const LTD_LP_Experiment = 'LTD LP Experiment';
const VIDEO_THUMBNAIL_IN_BANNER = 'MB Banner Video Experiment';
const SEATMAP_EXPERIMENT = 'Venue Seats Page Revamp';
const SUBATTRACTIONS_EXPERIMENT = 'Sub-Attractions Page Revamp';
const PRODUCT_CARD_VIDEO = 'Product Card Video Experiment V1';
const C1_COLLECTION_PRODUCT_CARD_CTA_EXPERIMENT_ENGLISH = 'EN LP CTA Copy v2';
const CRUISES_COMBO_REVAMP = 'Cruises Revamp Experiment For Combos';
const HOHO_REVAMP_PARIS_BARCELONA = 'HOHO Mweb v3';
const DAY_TRIPS_LISTICLE = 'Day Trips Listicle Experiment';
const MIXPANEL_SESSION_REPLAY = 'Mixpanel Session Replay';
const DAY_TRIPS_COLLECTION_DWEB = 'Day Trips Collection Experiment v2';
const DAY_TRIPS_COLLECTION_MWEB = 'Day Trips Collection Experiment MWeb';
const FLEXIBLE_CANCELLATION_EXPERIMENT = 'Cancellation Insurance v1';
const QNA_EXPERIMENT = 'Q&A Experiment v2';
const POI_FILTERS_EXPERIMENT = 'POI Filters & Boosters Experiment';
const DROPS_BANNER_EXPERIMENT = 'Drops Banner Experiment';

export const EXPERIMENT_NAMES: Record<string, string> = {
  C1_COLLECTION_PRODUCT_CARD_CTA_EXPERIMENT_ENGLISH,
  CRUISES_COMBO_REVAMP,
  DAY_TRIPS_COLLECTION_DWEB,
  DAY_TRIPS_COLLECTION_MWEB,
  DAY_TRIPS_LISTICLE,
  DROPS_BANNER_EXPERIMENT,
  FLEXIBLE_CANCELLATION_EXPERIMENT,
  HOHO_REVAMP_PARIS_BARCELONA,
  LFC_IMPACT,
  LTD_LP_Experiment,
  MIXPANEL_SESSION_REPLAY,
  POI_FILTERS_EXPERIMENT,
  PRODUCT_CARD_VIDEO,
  QNA_EXPERIMENT,
  SEATMAP_EXPERIMENT,
  SUBATTRACTIONS_EXPERIMENT,
  VIDEO_THUMBNAIL_IN_BANNER,
};

/**
 * Experiments List
 * Ensure Experiment Variant at index 0 is always Control (Current) Variant.
 */
export const EXPERIMENTS: Record<string, Experiment> = {
  [C1_COLLECTION_PRODUCT_CARD_CTA_EXPERIMENT_ENGLISH]: new Experiment(
    C1_COLLECTION_PRODUCT_CARD_CTA_EXPERIMENT_ENGLISH,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [100, 0]
  ),
  [CRUISES_COMBO_REVAMP]: new Experiment(
    CRUISES_COMBO_REVAMP,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [50, 50]
  ),
  [DAY_TRIPS_COLLECTION_DWEB]: new Experiment(
    DAY_TRIPS_COLLECTION_DWEB,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [50, 50]
  ),
  [DAY_TRIPS_COLLECTION_MWEB]: new Experiment(
    DAY_TRIPS_COLLECTION_MWEB,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [100, 0]
  ),
  [DAY_TRIPS_LISTICLE]: new Experiment(
    DAY_TRIPS_LISTICLE,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [100, 0]
  ),
  [DROPS_BANNER_EXPERIMENT]: new Experiment(
    DROPS_BANNER_EXPERIMENT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [80, 20]
  ),
  [FLEXIBLE_CANCELLATION_EXPERIMENT]: new Experiment(
    FLEXIBLE_CANCELLATION_EXPERIMENT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [50, 50]
  ),
  [HOHO_REVAMP_PARIS_BARCELONA]: new Experiment(
    HOHO_REVAMP_PARIS_BARCELONA,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [0, 100]
  ),

  [LFC_IMPACT]: new Experiment(
    LFC_IMPACT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [100, 0]
  ),
  [LTD_LP_Experiment]: new Experiment(
    LTD_LP_Experiment,
    [VARIANTS.SHOWPAGE_REDIRECT, VARIANTS.CHECKOUT_REDIRECT],
    [0, 100]
  ),
  [MIXPANEL_SESSION_REPLAY]: new Experiment(
    MIXPANEL_SESSION_REPLAY,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [95, 5]
  ),
  [POI_FILTERS_EXPERIMENT]: new Experiment(
    POI_FILTERS_EXPERIMENT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [100, 0]
  ),
  [PRODUCT_CARD_VIDEO]: new Experiment(
    PRODUCT_CARD_VIDEO,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [0, 100]
  ),
  [QNA_EXPERIMENT]: new Experiment(
    QNA_EXPERIMENT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [50, 50]
  ),
  [SEATMAP_EXPERIMENT]: new Experiment(
    SEATMAP_EXPERIMENT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [50, 50]
  ),
  [SUBATTRACTIONS_EXPERIMENT]: new Experiment(
    SUBATTRACTIONS_EXPERIMENT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [100, 0]
  ),
  [VIDEO_THUMBNAIL_IN_BANNER]: new Experiment(
    VIDEO_THUMBNAIL_IN_BANNER,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [100, 0]
  ),
};
